import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["logoutButton"]

  signOut(event) {
    if (this._isTurboNativeApp) {
      event.preventDefault()
      event.stopImmediatePropagation()
      window.TurboNativeBridge.postMessage("signOut")
    } else if (this.hasLogoutButtonTarget) {
      this.logoutButtonTarget.setAttribute("data-turbo", "false")
    }
  }

  switchAccount(event) {
    if (this._isTurboNativeApp) {
      event.preventDefault()
      event.stopImmediatePropagation()
      window.TurboNativeBridge.postMessage("switchAccount", { prefix: event.params.prefix })
    }
  }

  get _isTurboNativeApp() {
    return navigator.userAgent.includes("Turbo Native")
  }
}
