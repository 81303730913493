import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addLangButton", "secondLang", "lang1Select", "lang2Select"]
  static values = {
    language1: String,
    language2: String,
    object: String
  }

  addSecondLang(event) {
    event.currentTarget.classList.toggle("hidden")
    this.secondLangTarget.classList.toggle("hidden")

    if (this.objectValue.class === 'Department') {
      this.lang2SelectTarget.value = this.language2Value
      this.checkLangs()
    }
  }

  removeSecondLang() {
    this.secondLangTarget.classList.toggle("hidden")
    this.lang2SelectTarget.value = ''
    this.addLangButtonTarget.classList.toggle("hidden")

    if (this.objectValue.class === 'Department' && this.lang1SelectTarget.value === '') {
      this.lang1SelectTarget.value = this.language1Value
    }
  }

  checkLangs() {
    if ((this.lang1SelectTarget.value === this.language1Value && this.lang2SelectTarget.value === this.language2Value) ||
      (this.lang1SelectTarget.value === this.language1Value && this.lang2SelectTarget.value === '') ||
      (this.lang1SelectTarget.value === '' && this.lang2SelectTarget.value === this.language2Value)) {
      this.lang1SelectTarget.value = ''
      this.lang2SelectTarget.value = ''
    }
  }
}
