import { Controller } from "@hotwired/stimulus"
// import { template } from "lodash"
// import { forceSetValueOnSelect } from "./helpers"

export default class extends Controller {
  static targets = ["destroy", "newAddressTemplate", "taxNo", "addressMore", "buttonLess", "buttonMore", "participation"]
  static values = {
    index: { type: Number, default: 0 },
    kind: String,
    listId: String
  }

  static outlets = ["invoice"]

  newAddressTemplate

  connect() {
    if (this.hasNewAddressTemplateTarget) {
      this.newAddressTemplate = this.newAddressTemplateTarget.innerHTML
      this.newAddressTemplate = this.newAddressTemplate.replaceAll(/attributes_country/g, "attributes_$index_country")
      this.newAddressTemplate = this.newAddressTemplate.replaceAll(/country]/g, "$index][country]")
    }
  }

  add() {
    document.getElementById(this.listIdValue).insertAdjacentHTML('beforeend', this.newAddressTemplate.replaceAll(/\$index/g, parseInt(this.indexValue)))
    this.indexValue += 1
  }

  switchType(event) {
    if (event.target.value === "true") {
      document.querySelector(`[data-recipient-person="${this.indexValue}"]`).classList.add("hidden")
      document.querySelector(`[data-recipient-company="${this.indexValue}"]`).classList.remove("hidden")
      document.querySelector(`[data-recipient-company="${this.indexValue}"]`).after(this.taxNoTarget)
      if (this.invoiceOutlet.localeValue === "fr") {
        this.taxNoTarget.classList.remove("hidden")
      }
    } else {
      document.querySelector(`[data-recipient-company="${this.indexValue}"]`).classList.add("hidden")
      document.querySelector(`[data-recipient-person="${this.indexValue}"]`).classList.remove("hidden")
      this.addressMoreTarget.append(this.taxNoTarget)
      if (this.invoiceOutlet.localeValue === "fr") {
        this.taxNoTarget.classList.add("hidden")
      }
    }
  }

  toggleAdditional() {
    this.addressMoreTargets.forEach((target) => target.classList.toggle("hidden"))
    if (this.buttonLessTarget.classList.contains("hidden")) {
      this.buttonMoreTarget.classList.add("hidden")
      this.buttonLessTarget.classList.remove("hidden")
    } else {
      this.buttonLessTarget.classList.add("hidden")
      this.buttonMoreTarget.classList.remove("hidden")
    }
  }

  delete(event) {
    const confirmed = confirm(event.target.getAttribute("data-confirm"))
    if (confirmed) {
      this.element.classList.add("hidden")
      this.destroyTarget.checked = true
    }
  }

  showParticipation(event) {
    if (event.currentTarget.value === "Dodatkowy nabywca") { // tylko wtedy pokazujemy pole na udział
      this.participationTarget.classList.remove("hidden")
    } else {
      this.participationTarget.classList.add("hidden")
    }
  }
}
