import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  click() {
    if (window.innerWidth < 640) {
      window.location.href = this.urlValue
    }

    return false
  }
}
