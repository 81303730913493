import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "content"]

  connect() {
    this.closeAllItems()
  }

  toggle(event) {
    const itemIndex = this.itemTargets.indexOf(event.currentTarget)

    this.closeAllItems()

    if (itemIndex >= 0) {
      const contentTarget = this.contentTargets[itemIndex]

      if (contentTarget) {
        contentTarget.classList.toggle("hidden")

        if (itemIndex === this.itemTargets.length - 1) {
          this.itemTargets.forEach((itemTargetElement) => {
            itemTargetElement.classList.remove("rounded-b-xl")
          })
        } else {
          const lastItemTarget = this.itemTargets[this.itemTargets.length - 1]
          if (lastItemTarget) {
            lastItemTarget.classList.add("rounded-b-xl")
          }
        }
      }
    }
  }

  closeAllItems() {
    this.contentTargets.forEach((target) => {
      target.classList.add("hidden")
    })
  }
}
