import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["menu"]

  eventCreated = false

  connect() {
    console.log('dropdown connect')
    if (this.hasMenuTarget) {
      useTransition(this, { element: this.menuTarget })
    }
  }

  toggle() {
    this.toggleTransition()
    // https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      document.addEventListener("click", fn)
    } else {
      this.removeEvent()
    }
  }

  hide() {
    this.leave()
    this.removeEvent()
  }

  outsideHide(event) {
    // this.hide(event)
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.leave()
      this.removeEvent()
    }
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      document.removeEventListener("click", this.eventCreated)
      this.eventCreated = false
    }
  }

  disconnect() {
    this.removeEvent()
  }
}
