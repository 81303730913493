import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["body", "input"]
  static values = {
    outsideOpenId: String
  }

  switchType(event) {
    if (event.currentTarget.value === "manual") {
      this.bodyTarget.classList.add("hidden")
      this.inputTargets.forEach((input) => {
        input.required = false
      })
    } else {
      this.bodyTarget.classList.remove("hidden")
      this.inputTargets.forEach((input) => {
        input.required = true
      })
    }
  }
}
