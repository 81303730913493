import { application } from "./application"
import TurboAppController from "./turbo_app_controller"
import HelloController from "./hello_controller"
import DropdownController from "./dropdown_controller"
import DropdownStatusController from "./dropdown_status_controller"
import DropdownClientController from "./dropdown_client_controller"
import DropdownInvoiceProductController from "./dropdown_invoice_product_controller"
import DropdownInvoicesController from "./dropdown_invoices_controller"
import ModalController from "./modal_controller"
import ExpansionController from "./expansion_controller"
import InvoiceController from "./invoice_controller"
import InvoiceListController from "./invoice_list_controller"
import InvoiceLangController from "./invoice_lang_controller"
import InvoiceSendEmailController from "./invoice_send_email_controller"
import InvoiceContractorsController from "./invoice_contractors_controller"
import NotificationController from "./notification_controller"
import AccountController from "./account_controller"
import MobileTabController from "./mobile_tab_controller"
import MobilePaymentController from "./mobile_payment"
import FormsController from "./forms_controller"
import ProductController from "./product_controller"
import InvoicePositionController from "./invoice_position_controller"
import FileUploaderController from "./file_uploader_controller"
import DropdownCompanySearchController from "./dropdown_company_search_controller"
import MoreOptionsController from "./more_options_controller"
import HelplinkController from "./helplink_controller"
import SimpleHideController from "./simple_hide_controller"
import TabsController from "./tabs_controller"
import AdditionalOptionsController from "./additional_options_controller"
import MultiselectController from "./multiselect_controller"
import CurrencyController from "./currency_controller"
import ThemeController from "./theme_controller"
import ButtonLoaderController from "./button_loader_controller"
import TailwindToggleController from "./tailwind_toggle_controller"
import LangChangeController from "./lang_change_controller"
import ClientController from "./client_controller"
import EstimateModalController from "./estimate_modal_controller"
import RadioAccordionController from "./radio_accordion_controller"
import DepartmentController from "./department_controller"
import MossController from "./moss_controller"
import XsLinkController from "./xs_link_controller"
import ReadMoreController from "./read_more_controller"
import InvoiceTemplatesController from "./invoice_templates_controller"
import DesController from "./des_controller"
import RestrictionModalController from "./restriction_modal_controller"
import ViesController from "./vies_controller"
import WarehouseController from "./warehouse_controller"
import DeliveryAddressController from "./delivery_address_controller"
import SummationController from "./summation_controller"
import AccountSettingsController from "./account_settings_controller"
import MaskController from "./mask_controller"
import InvoiceAccountingDatesController from "./invoice_accounting_dates_controller"
import InvoiceBulkController from "./invoice/bulk_controller"
import OcrController from "./ocr_controller"

application.register("turbo-app", TurboAppController)
application.register("hello", HelloController)
application.register("dropdown", DropdownController)
application.register("dropdown-status", DropdownStatusController)
application.register("dropdown-client", DropdownClientController)
application.register("dropdown-invoice-product", DropdownInvoiceProductController)
application.register("dropdown-invoices", DropdownInvoicesController)
application.register("modal", ModalController)
application.register("expansion", ExpansionController)
application.register("invoice", InvoiceController)
application.register("invoice-list", InvoiceListController)
application.register("invoice-lang", InvoiceLangController)
application.register("invoice-send-email", InvoiceSendEmailController)
application.register("invoice-contractors", InvoiceContractorsController)
application.register('notification', NotificationController)
application.register('account', AccountController)
application.register('mobile-tab', MobileTabController)
application.register("mobile-payment", MobilePaymentController)
application.register('forms', FormsController)
application.register('invoice-position', InvoicePositionController)
application.register('file-uploader', FileUploaderController)
application.register("dropdown-company-search", DropdownCompanySearchController)
application.register("more-options", MoreOptionsController)
application.register('product', ProductController)
application.register("helplink", HelplinkController)
application.register("simple-hide", SimpleHideController)
application.register("tabs", TabsController)
application.register("additional-options", AdditionalOptionsController)
application.register("multiselect", MultiselectController)
application.register("currency", CurrencyController)
application.register("theme", ThemeController)
application.register("button-loader", ButtonLoaderController)
application.register("tailwind-toggle", TailwindToggleController)
application.register("lang-change", LangChangeController)
application.register("client", ClientController)
application.register("estimate-modal", EstimateModalController)
application.register("radio-accordion", RadioAccordionController)
application.register("department", DepartmentController)
application.register("moss", MossController)
application.register("xs-link", XsLinkController)
application.register("read-more", ReadMoreController)
application.register("invoice-templates", InvoiceTemplatesController)
application.register("des", DesController)
application.register("restriction-modal", RestrictionModalController)
application.register("vies", ViesController)
application.register("warehouse", WarehouseController)
application.register("delivery-address", DeliveryAddressController)
application.register("summation", SummationController)
application.register("account-settings", AccountSettingsController)
application.register("mask", MaskController)
application.register("invoice-accounting-dates", InvoiceAccountingDatesController)
application.register("invoice--bulk", InvoiceBulkController)
application.register("ocr", OcrController)
