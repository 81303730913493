import { Controller } from "@hotwired/stimulus"
import { showNotification, isEmpty } from "./helpers"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    manuallyUnchecked: { type: Boolean, default: false },
    previousMossCountry: String,
    tryAutoCheck: Boolean,
    euVatRates: Object,
    validCountries: Array,
    translations: Object,
  }

  static outlets = ["invoice"]

  static targets = ["useMoss"]

  get recipientCountry() {
    return this.recipientCountryElem()?.value
  }

  set recipientCountry(value) {
    const element = this.recipientCountryElem()
    if (element) {
      element.value = value
    }
  }

  inputs() {
    const h = {
      seller_country: document.getElementById("invoice_seller_country").value,
      buyer_country: document.getElementById("invoice_buyer_country").value,
      buyer_tax_no: "",
      max_one_recipient: this.recipientInLimit()
    }
    // TODO: tych pól jeszcze nie ma??
    const useSendFrom = document.getElementById("invoice_use_send_from")?.checked
    if (useSendFrom) {
      const sendFrom = document.getElementById("invoice_send_from").value
      if (sendFrom) {
        h.seller_country = sendFrom
      }
    }

    if (this.recipientCountry) {
      h.buyer_country = this.recipientCountry
    }
    if (isEmpty(document.getElementById("invoice_buyer_tax_no_kind")?.value)) {
      h.buyer_tax_no = document.getElementById("invoice_buyer_tax_no").value
    }
    return h
  }

  trySetManual() {
    if (this.useMossTarget.checked) {
      this.isMossApplicable(this.strictAsk()).then(({ applicable, country, notice }) => {
        if (!isEmpty(notice)) {
          showNotification(this.translationsValue[notice])
        }
        country = this.checkCountry(country)
        if (applicable) {
          this.setMossTaxes(country)
          showNotification(this.translationsValue["moss_set"])
        } else {
          this.useMossTarget.checked = false
        }
      })
    } else {
      this.manuallyUncheckedValue = true
      this.invoiceOutlet.setDefaultTaxes()
      showNotification(this.translationsValue["moss_canceled"])
    }
  }

  update() {
    if (this.useMossTarget.checked) {
      this.isMossApplicable(() => true).then(({ applicable, country, notice }) => {
        country = this.checkCountry(country)
        if (applicable) {
          if (country !== this.previousMossCountryValue) {
            this.setMossTaxes(country)
            showNotification(this.translationsValue["moss_set"])
          }
        } else {
          this.useMossTarget.checked = false
          if (this.invoiceOutlet.handleCondNoteValue) {
            this.invoiceOutlet.updateCondNote()
          }
          this.invoiceOutlet.setDefaultTaxes()
          if (notice === "tax_no_exist") {
            this.invoiceOutlet.showTaxNoExistNotification()
          }
          if (notice === "tax_no_country_not_applicable_for_moss") {
            showNotification(this.translationsValue[notice])
          }
          showNotification(this.translationsValue["moss_canceled"])
        }
        if (notice === "undefined_moss_applicability" || notice === "undefined_moss_applicability_warning") {
          showNotification(this.translationsValue[notice])
        }
      })
    } else if (this.tryAutoCheckValue) {
      this.trySetAutoMoss()
    }
  }

  trySetAutoMoss() {
    if (!this.manuallyUncheckedValue) {
      this.isMossApplicable(() => true).then(({ applicable, country, notice }) => {
        country = this.checkCountry(country)
        if (applicable) {
          if (!this.useMossTarget.checked || country !== this.previousMossCountryValue) {
            this.useMossTarget.checked = true
            if (this.invoiceOutlet.handleCondNoteValue) {
              this.invoiceOutlet.updateCondNote()
            }
            if (notice === "undefined_moss_applicability" || notice === "undefined_moss_applicability_warning") {
              showNotification(this.translationsValue[notice])
            }
          }
        } else if (notice === "tax_no_exist") {
          this.invoiceOutlet.showTaxNoExistNotification()
        }
      })
    }
  }

  setMossTaxes(country) {
    this.previousMossCountryValue = country
    const mossTaxOptions = document.createElement("select")
    this.euVatRatesValue[country].forEach((tax) => {
      const option = document.createElement("option")
      option.value = tax
      option.innerText = tax
      mossTaxOptions.appendChild(option)
    })
    if (!this.invoiceOutlet.setTaxOptions(Array.from(mossTaxOptions.options))) {
      showNotification(this.translationsValue["wrong_tax_selected"])
    }
  }

  checkCountry(country) {
    if (!isEmpty(country)) {
      if (!isEmpty(this.recipientCountry)) {
        this.recipientCountry = country
      } else {
        this.invoiceOutlet.buyerCountryTarget.value = country
      }
    }
    return isEmpty(this.recipientCountry) ? this.invoiceOutlet.buyerCountryTarget.value : this.invoiceOutlet.recipientCountry
  }

  recipientInLimit() {
    if (this.invoiceOutlet.ksefEnabledValue) {
      // ROLES = ["Odbiorca", "Dodatkowy nabywca", ...]
      // w KSeFie pozwalamy na max 1 Odbiorcę i żadnego Dodatkowego nabywcy (żeby dało się włączyć oss)
      const recipientsCount = document.querySelectorAll("div[data-controller='invoice-contractors']:not(.hidden) select[id^='invoice_recipients_attributes_'][id$='_role'] option[value='Odbiorca']:checked").length
      const additionalBuyerCount = document.querySelectorAll("div[data-controller='invoice-contractors']:not(.hidden) select[id^='invoice_recipients_attributes_'][id$='_role'] option[value='Dodatkowy nabywca']:checked").length
      return recipientsCount <= 1 && additionalBuyerCount === 0
    } else {
      // w nie-KSeFie nie ma ról więc po prostu nie może być więcej niż 1 Odbiorcy
      return document.querySelectorAll("input[id^='invoice_recipients_attributes_'][id$='__destroy']:not(:checked)").length <= 1
    }
  }

  // znajduje pole z krajem odbiorcy (zakładamy, że jest tylko 1 widoczny odbiorca na formularzu)
  recipientCountryElem() {
    if (this.invoiceOutlet.ksefEnabledValue) {
      // const role = $('.recipient_role option[value="<%= Addresses::Recipient::ROLES.first %>"]:selected').parent(":visible:first")
      const role = document.querySelector("div[data-controller='invoice-contractors']:not(.hidden) select[id^='invoice_recipients_attributes_'][id$='_role'] option[value='Odbiorca']:checked")
      return role?.closest("[data-controller='invoice-contractors']").querySelector("[id^='invoice_recipients_attributes_'][id$='_country']")
    } else {
      return document.querySelector("[id^='invoice_recipients_attributes_'][id$='_country']")
    }
  }

  // sprawdza czy limit odbiorców został przekroczony i jak tak to wyłącza checkbox oss
  recipientCheck() {
    if (this.recipientInLimit()) {
      this.useMossTarget.removeAttribute("disabled")
    } else {
      this.useMossTarget.setAttribute("disabled", true)
    }
    this.update()
  }

  /** funkcje z _moss_validator.js.erb */
  ueCountry(country) {
    return this.invoiceOutlet.euCountriesValue.includes(country)
  }

  validCountry(country) {
    return this.validCountriesValue.includes(country)
  }

  sameVatCountry(country1, country2) {
    const franceVatZone = ["FR", "MC"]
    return (country1 === country2 || (franceVatZone.includes(country1) && franceVatZone.includes(country2)))
  }

  /**
   * @param {() => boolean} strict
   * @returns {Promise<{applicable: boolean, notice: string|null, country: string|null}>}
   **/
  isMossApplicable(strict) {
    return new Promise((resolve) => {
      let notice = null
      let applicable = null
      const inputs = this.inputs()
      const sellerCountry = inputs.seller_country.trim().toUpperCase()
      const buyerCountry = inputs.buyer_country.trim().toUpperCase()
      const buyerTaxNo = inputs.buyer_tax_no.trim()
      if (!inputs.max_one_recipient) {
        notice = "over_max_recipients"
        applicable = false
      } else if (isEmpty(sellerCountry)) {
        notice = "seller_country_not_applicable_for_moss"
        applicable = false
      } else if (this.sameVatCountry(sellerCountry, buyerCountry)) {
        notice = "same_seller_and_buyer_country"
        applicable = false
      } else if (isEmpty(buyerCountry) && isEmpty(buyerTaxNo)) {
        notice = "please_enter_buyer_country_or_tax_no"
        applicable = false
      } else if (isEmpty(buyerTaxNo)) {
        if (!this.validCountry(buyerCountry)) {
          notice = "buyer_country_not_valid"
          applicable = false
        } else if (!this.ueCountry(buyerCountry)) {
          notice = "buyer_country_not_applicable_for_moss"
          applicable = false
        } else {
          applicable = true
        }
      } else {
        const taxNoCountryMatch = buyerTaxNo.toUpperCase().match(/^[A-Z][A-Z]/)
        let taxNoCountry = taxNoCountryMatch !== null ? String(taxNoCountryMatch) : ""
        if (taxNoCountry === "EL") {
          taxNoCountry = "GR"
        }
        if (isEmpty(taxNoCountry) && isEmpty(buyerCountry)) {
          applicable = false
          notice = "buyer_country_not_specified"
        } else if (!isEmpty(taxNoCountry) && !this.ueCountry(taxNoCountry) && isEmpty(buyerCountry)) {
          applicable = false
          notice = "tax_no_country_not_applicable_for_moss"
        } else if (!isEmpty(taxNoCountry) && !this.ueCountry(taxNoCountry) && !isEmpty(buyerCountry) && this.ueCountry(buyerCountry)) {
          applicable = true
        } else if (!isEmpty(buyerCountry) && !this.ueCountry(buyerCountry)) {
          if (!this.validCountry(buyerCountry)) {
            applicable = false
            notice = "buyer_country_not_valid"
          } else {
            applicable = false
            notice = "buyer_country_not_applicable_for_moss"
          }
        } else {
          const timestamp = this.nextTimestamp()
          Rails.ajax({
            url: this.invoiceOutlet.viesUrlValue,
            type: "get",
            data: new URLSearchParams({ tax_no: inputs.buyer_tax_no, country: inputs.buyer_country, timestamp: timestamp }),
            success: (data) => {
              const ts = parseInt(data.timestamp)
              if (this._lastTimestamp < ts) {
                this._lastTimestamp = ts
              } else {
                return
              }

              if (this.sameVatCountry(data.country, sellerCountry)) {
                notice = "same_seller_and_buyer_country"
                applicable = false
              } else if (data.valid == null) {
                if (strict()) {
                  notice = "undefined_moss_applicability"
                  applicable = false
                } else {
                  notice = "undefined_moss_applicability_warning"
                  applicable = true
                }
              } else if (data.valid === false) {
                if (data.country === "") {
                  notice = "buyer_country_not_specified"
                  applicable = false
                } else {
                  notice = "tax_no_not_exist"
                  applicable = true
                }
              } else {
                notice = "tax_no_exist"
                applicable = false
              }

              resolve({ applicable, notice, country: data.country })
            },
            error: () => {
              if (this._lastTimestamp < timestamp) {
                this._lastTimestamp = timestamp
              } else {
                return
              }

              if (strict()) {
                notice = "undefined_moss_applicability"
                applicable = false
              } else {
                notice = "undefined_moss_applicability_warning"
                applicable = true
              }
              resolve({ applicable: applicable, notice: notice, country: null })
            }
          })
          return
        }
      }
      this.discard()
      resolve({ applicable, notice, country: null })
    })
  }

  /** @returns {() => boolean} */
  strictAsk() {
    return () => !confirm(this.translationsValue["undefined_moss_applicability_confirm"])
  }

  _lastTimestamp = 0
  _timestamp = 1

  nextTimestamp() {
    return this._timestamp++
  }

  isAjaxBusy() {
    return !(this._timestamp === this._lastTimestamp + 1)
  }

  discard() {
    this._lastTimestamp = this._timestamp - 1
  }
}
