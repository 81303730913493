import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["periodMoreField", "searchFilters", "searchFiltersToggleText", "searchFiltersArrow", "searchField", "exactCheckbox", "exactCheckboxWrapper"]
  static values = { showMore: String, showLess: String }
  static classes = ["rotate"]

  toggleSearchFilters() {
    this.searchFiltersTarget.classList.toggle("hidden")
    this.searchFiltersArrowTarget.classList.toggle(this.rotateClass)
    this.searchFiltersToggleTextTarget.innerText = this.searchFiltersTarget.classList.contains("hidden") ? this.showMoreValue : this.showLessValue
  }

  onQueryChange() {
    const shouldDisplayExact = this.searchFieldTarget.value.length >= 3
    if (shouldDisplayExact) {
      this.exactCheckboxWrapperTarget.classList.remove("hidden")
    } else {
      this.exactCheckboxWrapperTarget.classList.add("hidden")
      this.exactCheckboxTarget.checked = false
    }
  }

  onPeriodChange(event) {
    if (event.target.value === "more") {
      event.target.parentNode.classList.add("hidden")
      for (const element of this.periodMoreFieldTargets) {
        element.classList.remove("hidden")
        for (const child of element.querySelectorAll("[disabled]")) {
          child.disabled = false
        }
      }
    }
  }
}
