import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["taxNo", "shortcutName"]

  connect() {
    console.log("client connect", this.element)
  }

  switchType(event) {
    if (event.target.value === "true") {
      document.querySelectorAll("[data-company]").forEach((element) => element.classList.remove("hidden"))
      document.querySelector("#client_name").closest("[data-company]").after(this.taxNoTarget)
    } else {
      document.querySelectorAll("[data-company]").forEach((element) => element.classList.add("hidden"))
      document.querySelector("#client_last_name").closest("div").after(this.taxNoTarget)
    }
  }

  setShortcut(event) {
    if (this.hasShortcutNameTarget && this.shortcutNameTarget.value === "") {
      this.shortcutNameTarget.value = event.currentTarget.value
    }
  }
}
