import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["knob", "input", "button"]
  static values = { value: String, checked: Boolean }
  static classes = ["wrapperUnchecked", "wrapperChecked", "knobUnchecked", "knobChecked"]

  toggle() {
    this.checkedValue = !this.checkedValue
  }

  checkedValueChanged(newVal, oldVal) {
    if (this.checkedValue) {
      this.element.classList.remove(this.wrapperUncheckedClass)
      this.element.classList.add(this.wrapperCheckedClass)
      this.knobTarget.classList.remove(this.knobUncheckedClass)
      this.knobTarget.classList.add(this.knobCheckedClass)
      this.inputTarget.value = this.valueValue
    } else {
      this.element.classList.remove(this.wrapperCheckedClass)
      this.element.classList.add(this.wrapperUncheckedClass)
      this.knobTarget.classList.remove(this.knobCheckedClass)
      this.knobTarget.classList.add(this.knobUncheckedClass)
      this.inputTarget.value = ""
    }
    if (newVal !== oldVal) {
      this.dispatch("change", { detail: { checked: this.checkedValue } })
    }
  }

  changeState(event) {
    event.preventDefault()
    const state = event.detail.state
    switch (state) {
      case "disabled":
        this.checkedValue = false
        this.buttonTarget.disabled = false
        break
      case "enabled":
        this.checkedValue = true
        this.buttonTarget.disabled = false
        break
      case "blocked":
        this.checkedValue = false
        this.buttonTarget.disabled = true
        break
      default:
        break
    }
  }
}
