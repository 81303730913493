import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["client", "input", "typeRadio", "loaderImg", "errorExplanation"]
  static values = { locale: String, endpointUrl: String, placeholder: String, prefix: String }

  get selectedType() {
    return this.typeRadioTargets.find((element) => element.checked)?.value
  }

  connect() {
    console.log("Connected to Company Search Controller")
    useTransition(this, { element: this.clientTarget })
  }

  open() {
    this.enter()
    this.inputTarget.value = ''
    this.inputTarget.focus()
    this.clientTarget.classList.remove('hidden')
  }

  changeType(event) {
    this.inputTarget.placeholder = `${this.placeholderValue} ${event.params.label}`
  }

  submit() {
    this.loaderImgTarget.classList.remove('hidden')
    this.setErrors([])

    const params = { type: this.selectedType }
    // OSTROŻNIE: czasem drugi parametr to "number", czasem "numer"
    let handleResults
    switch (this.localeValue) {
      case "pl":
        params.numer = this.inputTarget.value
        handleResults = this.handleResultsPl
        break
      case "uk":
        params.number = this.inputTarget.value
        handleResults = this.handleResultsUk
        break
      case "cz":
        params.number = this.inputTarget.value
        handleResults = this.handleResultsCz
        break
      case "sk":
        params.number = this.inputTarget.value
        handleResults = this.handleResultsSk
        break
      case "fr":
        params.numer = this.inputTarget.value
        handleResults = this.handleResultsFr
        break
      default:
        throw new Error(`unknown locale: ${this.localeValue}`)
    }
    handleResults = handleResults.bind(this)

    Rails.ajax({
      type: 'post',
      url: this.endpointUrlValue,
      dataType: 'json',
      data: new URLSearchParams(params),
      success: (data) => {
        this.loaderImgTarget.classList.add('hidden')
        if (!(Array.isArray(data.errors) && data.errors.length > 0)) {
          handleResults(data)
          this.hide()
        } else {
          this.setErrors(data.errors)
        }
      },
    })
  }

  hide() {
    this.leave()
  }

  /** @param {string[]} errors */
  setErrors(errors) {
    const errorElements = errors.map((error) => {
      const element = document.createElement("p")
      element.innerText = error
      return element
    })
    this.errorExplanationTarget.replaceChildren(...errorElements)
    if (errors.length > 0) {
      this.errorExplanationTarget.classList.remove("hidden")
    } else {
      this.errorExplanationTarget.classList.add("hidden")
    }
  }

  /**
   * wrapper na ustawianie wartości elementom podobnie do jquery
   * czyli bez rzucania błędem jeśli nie zostanie znaleziony (bo np nie ma go w formatce)
   */
  trySetValue(id, value) {
    const element = document.getElementById(id)
    if (element !== null) {
      element.value = value
    }
  }

  handleResultsPl({ results }) {
    this.trySetValue(`${this.prefixValue}_name`, results.name)
    this.trySetValue(`${this.prefixValue}_tax_no`, results.nip)
    this.trySetValue(`${this.prefixValue}_street`, results.street)
    this.trySetValue(`${this.prefixValue}_post_code`, results.post_code)
    this.trySetValue(`${this.prefixValue}_city`, results.city)
  }

  handleResultsUk({ results }) {
    this.trySetValue(`${this.prefixValue}_name`, results.company_name)
    if (this.prefixValue === "department") {
      this.trySetValue(`${this.prefixValue}_kind`, results.company_status)
    } else {
      this.trySetValue(`${this.prefixValue}_shortcut`, results.company_name)
    }
    this.trySetValue(`${this.prefixValue}_street`, `${results.registered_office_address.address_line_1} ${results.registered_office_address.address_line_2}`)
    this.trySetValue(`${this.prefixValue}_post_code`, results.registered_office_address.postal_code)
    this.trySetValue(`${this.prefixValue}_city`, results.registered_office_address.locality)
  }

  handleResultsCz({ results, dic }) {
    this.trySetValue(`${this.prefixValue}_name`, results.obchodniJmeno)
    this.trySetValue(`${this.prefixValue}_register_number`, results.ico)
    this.trySetValue(`${this.prefixValue}_tax_no`, dic)
    this.trySetValue(`${this.prefixValue}_street`, results.sidlo.textovaAdresa.split(",")[0])
    this.trySetValue(`${this.prefixValue}_post_code`, results.sidlo.psc)
    this.trySetValue(`${this.prefixValue}_city`, results.sidlo.nazevObce)
    this.trySetValue(`${this.prefixValue}_country`, results.sidlo.kodStatu)
  }

  handleResultsSk({ results }) {
    this.trySetValue(`${this.prefixValue}_name`, results.name)
    this.trySetValue(`${this.prefixValue}_tax_no`, results.taxno)
    this.trySetValue(`${this.prefixValue}_street`, `${results.address.street} ${results.address.number}`)
    this.trySetValue(`${this.prefixValue}_post_code`, results.address.postal_code)
    this.trySetValue(`${this.prefixValue}_city`, results.address.municipality)
    this.trySetValue(`${this.prefixValue}_register_number`, results.regno)
    this.trySetValue(`${this.prefixValue}_country`, results.address.country_code.toUpperCase())
  }

  handleResultsFr({ results }) {
    this.trySetValue(`${this.prefixValue}_name`, results.name)
    this.trySetValue(`${this.prefixValue}_tax_no`, results.tax_no)
    this.trySetValue(`${this.prefixValue}_street`, results.address)
    this.trySetValue(`${this.prefixValue}_post_code`, results.postal_code)
    this.trySetValue(`${this.prefixValue}_city`, results.city)
    this.trySetValue(`${this.prefixValue}_country`, results.country)
    this.trySetValue(`${this.prefixValue}_tax_no_kind`, results.kind === "siren" ? "SIREN" : results.kind === "no_enterprise" ? "N° entreprise" : "")
    document.getElementById(`${this.prefixValue}_tax_no`)?.dispatchEvent(new InputEvent("change"))
  }
}
