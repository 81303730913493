import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { showNotification } from "./helpers"

export default class extends Controller {
  static targets = ["viesButton"]

  static values = {
    translations: Object,
  }

  static outlets = ["invoice"]

  checkTaxNo() {
    var inputs = {
      buyer_tax_no: document.getElementById("invoice_buyer_tax_no").value,
      buyer_country: this.invoiceOutlet.buyerCountryTarget.value
    }

    if (!inputs.buyer_tax_no || inputs.buyer_tax_no === "") {
      showNotification(this.translationsValue["vies_check_missing_tax_no"])
      return
    } else if (inputs.buyer_country === "") {
      showNotification(this.translationsValue["vies_check_missing_country"])
      return
    } else if (!this.invoiceOutlet.euCountriesValue.includes(inputs.buyer_country)) {
      showNotification(this.translationsValue["vies_check_not_eu_country"])
      return
    }

    inputs.buyer_country = this.isoToVat(inputs.buyer_country)

    this.checkTaxNoInVies(inputs.buyer_tax_no, inputs.buyer_country).then((result) => {
      if (result.valid === true) {
        showNotification(this.translationsValue["vies_status_positive"])
        this.wdtNotification(result.valid)
      } else if (result.valid === false) {
        showNotification(this.translationsValue["vies_status_negative"])
        this.wdtNotification(result.valid)
      } else {
        showNotification(this.translationsValue["vies_status_inaccessible"])
      }
      this.taxNoCountryMismatch(inputs)
    }).catch(err => {
      console.error(err)
    })
  }

  isoToVat(countryCode) {
    if (countryCode === "GR") {
      return "EL"
    }
    return countryCode
  }

  checkTaxNoInVies(taxNo, country) {
    return new Promise((resolve) => {
      Rails.ajax({
        url: this.invoiceOutlet.viesUrlValue,
        type: "get",
        data: new URLSearchParams({ tax_no: taxNo, country: country, timestamp: 0 }),
        success: (data) => {
          resolve(data)
        },
        error: (res) => {
          resolve({ fail: res })
        }
      })
    })
  }

  taxNoCountryMismatch(inputs) {
    if (this.invoiceOutlet.euCountriesValue.includes(inputs.buyer_tax_no.substring(0, 2)) && (inputs.buyer_tax_no.substring(0, 2) !== inputs.buyer_country)) {
      showNotification(this.translationsValue["vies_check_tax_no_country_mismatch"])
    }
  }

  wdtNotification(valid) {
    if (this.invoiceOutlet.kindValue !== "wdt" && valid) {
      showNotification(this.translationsValue["vies_check_may_be_wdt"])
    } else if (this.invoiceOutlet.kindValue === "wdt" && !valid) {
      showNotification(this.translationsValue["vies_check_wdt_conditions"])
    }
  }
}
