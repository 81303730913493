import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bulkList", "moreDropdown", "countInfo", "moreBtn", "record", "optionsMenu", "reverseOptionsMenu", "tableContainer",
    "blockedElem"]

  static outlets = ["summation"]
  static values = {
    tableHoverClass: String,
    tableHideClass: String,
    translations: Object
  }

  selectedRecords = []
  visibleBulkWidth = 0

  connect() {
    window.addEventListener("resize", this.updateMenu.bind(this))
  }

  disconnect() {
    window.removeEventListener("resize", this.updateMenu.bind(this))
  }

  bulkSwitch() {
    this.selectedRecords = this.recordTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value)
    const hasSelectedRecords = this.selectedRecords.length > 0

    if (this.hasOptionsMenuTarget) {
      this.optionsMenuTarget.classList.toggle("hidden", !hasSelectedRecords)
      if (hasSelectedRecords) {
        this.updateMenu()
      }
    }
    if (this.hasReverseOptionsMenuTarget) {
      this.reverseOptionsMenuTarget.classList.toggle("hidden", hasSelectedRecords)
    }

    this.updateCountInfo()
    if (this.hasSummationOutlet) {
      this.summationOutlet.updateTotals(this.selectedRecords)
    }

    this.toggleTableMargin(hasSelectedRecords)
  }

  toggleTableMargin(isVisible) {
    if (this.hasTableContainerTarget) {
      this.tableContainerTarget.classList.toggle(this.tableHoverClassValue, isVisible)
      this.tableContainerTarget.classList.toggle(this.tableHideClassValue, !isVisible)
    }
  }

  bulkSelectSwitch(event) {
    const checkedAll = event.currentTarget.checked

    this.recordTargets.forEach(record => {
      record.checked = checkedAll
    })

    this.bulkSwitch()
  }

  updateCountInfo() {
    const translationKey = this.selectedRecords.length === 1 ? "one" : (this.selectedRecords.length < 5 ? "few" : "other")
    this.countInfoTarget.innerHTML = this.translationsValue.countInfo[translationKey].replace("%{count}", this.selectedRecords.length)
  }

  updateMenu() {
    const list = this.bulkListTarget
    const dropdownContainer = this.moreDropdownTarget
    let showMore = false

    const blokedWidth = this.countInfoTarget.offsetWidth + (this.blockedElemTarget.offsetWidth / 4)
    const menuContainerWidth = list.parentElement.offsetWidth
    const availableWidth = menuContainerWidth - blokedWidth
    let totalWidth = blokedWidth

    if (this.visibleBulkWidth !== menuContainerWidth) {
      this.moreBtnTarget.classList.add("hidden")
      Array.from(dropdownContainer.children).forEach((item) => {
        this.updateSelectState(item, "destroy")
        const clone = item.cloneNode(true)
        item.remove()
        list.appendChild(clone)
        this.updateSelectState(item)
      })

      Array.from(list.children).forEach((item) => {
        totalWidth += item.offsetWidth
        if (totalWidth >= availableWidth) {
          this.updateSelectState(item, "destroy")
          const clone = item.cloneNode(true)
          item.remove()
          dropdownContainer.appendChild(clone)
          this.updateSelectState(item)
          showMore = true
        }
      })

      if (showMore) {
        this.moreBtnTarget.classList.remove("hidden")
      }
      this.visibleBulkWidth = menuContainerWidth
    }
  }

  //drut dla select2 do usuniecia po wymianie select2
  updateSelectState(item, action = "init") {
    if (item.hasAttribute("data-with-select")) {
      if (action === "init") {
        init_select()
      } else if (action === "destroy") {
        destroy_select()
      }
    }
  }
}
