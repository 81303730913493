import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["invoiceEmailFrom", "userEmailVerifyAlert", "departmentEmailVerifyAlert", "accountEmailVerifyAlert"]
  static values = {
    emailFromOptions: Array,
    invoiceEmailFromData: Object
  }

  connect() {
    this.targetsForEmail = this.mapEmailToTargets()
    this.handleDisplay()
  }

  mapEmailToTargets() {
    const targetsForEmail = {}

    this.emailFromOptionsValue.forEach(emailFrom => {
      const target = this.getTargetForEmail(emailFrom)
      if (target) {
        targetsForEmail[emailFrom] = target
      }
    })

    return targetsForEmail
  }

  getTargetForEmail(emailFrom) {
    if (this.invoiceEmailFromDataValue["department"]["own_email_settings"] && this.invoiceEmailFromDataValue["department"]["email_from"] && !this.invoiceEmailFromDataValue["department"]["verified"] && emailFrom.includes(this.invoiceEmailFromDataValue["department"]["email_from"])) {
      return this.departmentEmailVerifyAlertTarget
    } else if (this.invoiceEmailFromDataValue["account"]["email_from"] && !this.invoiceEmailFromDataValue["account"]["verified"] && emailFrom.includes(this.invoiceEmailFromDataValue["account"]["email_from"])) {
      return this.accountEmailVerifyAlertTarget
    } else if (!this.invoiceEmailFromDataValue["user"]["verified"] && emailFrom.includes(this.invoiceEmailFromDataValue["user"]["email_from"])) {
      return this.userEmailVerifyAlertTarget
    }
    return null
  }

  handleDisplay() {
    this.hideAllEmailAlerts()
    this.showSelectedEmailAlert()
  }

  hideAllEmailAlerts() {
    this.userEmailVerifyAlertTarget.classList.add("hidden")
    this.departmentEmailVerifyAlertTarget.classList.add("hidden")
    this.accountEmailVerifyAlertTarget.classList.add("hidden")
  }

  showSelectedEmailAlert() {
    const selectedValue = this.invoiceEmailFromTarget.value
    const selectedElement = this.targetsForEmail[selectedValue]

    if (selectedElement) {
      selectedElement.classList.remove("hidden")
    }
  }
}
