import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { forceSetValueOnSelect, showNotification } from "./helpers"

export default class extends Controller {
  static values = {
    tryAutoCheck: Boolean,
    translations: Object,
  }

  static outlets = ["invoice"]

  static targets = ["useDes"]

  previousDescription
  countryCodes
  skipFirstNotification
  manuallyUnchecked

  connect() {
    this.skipFirstNotification = false
    this.manuallyUnchecked = false
    this.countryCodes = {}
    /** @type {HTMLOptionsCollection} */
    const countryOptions = this.invoiceOutlet.buyerCountryTarget.options
    for (const countryOption of countryOptions) {
      this.countryCodes[countryOption.value] = countryOption.innerText
    }
  }

  trySetManual() {
    if (this.useDesTarget.checked) {
      this.useDesTarget.checked = false

      this.isDesApplicable().then((result) => {
        if (result.applicable) {
          this.useDesTarget.checked = true
          this.setReverseChargeChecked(true)
          showNotification(this.translationsValue["des_issued"])
          this.hideTaxes()
        } else {
          showNotification(this.translationsValue[result.notice])
        }
      })
    } else {
      this.manuallyUnchecked = true
      this.setReverseChargeChecked(false)
      this.restoreTaxes()
      showNotification(this.translationsValue["des_canceled"])
    }
  }

  updateSkipFirstNotification() {
    this.skipFirstNotification = true
    this.update()
  }

  update() {
    if (this.tryAutoCheckValue) {
      if (!this.manuallyUnchecked) {
        this.isDesApplicable().then((result) => {
          if (result.applicable) {
            this.useDesTarget.checked = true
            this.setReverseChargeChecked(true)
            this.hideTaxes()
            this.invoiceOutlet.showTaxNoExistNotification()
            showNotification(this.translationsValue["des_issued"])
            this.skipFirstNotification = false
          } else {
            if (!this.skipFirstNotification) {
              if (result.notice === "tax_no_not_exist") {
                this.invoiceOutlet.showTaxNoNotExistNotification()
              } else {
                showNotification(this.translationsValue[result.notice])
              }
            }
            this.useDesTarget.checked = false
            this.restoreTaxes(false)
            this.skipFirstNotification = false
          }
        })
      } else {
        this.isDesApplicable().then((result) => {
          if (result.applicable) {
            this.invoiceOutlet.showTaxNoExistNotification()
          }
        })
      }
    } else if (this.useDesTarget.checked) {
      this.skipFirstNotification = false
      this.isDesApplicable().then((result) => {
        if (!result.applicable) {
          this.useDesTarget.checked = false
          showNotification(this.translationsValue[result.notice])
          this.restoreTaxes()
        }
      })
    }
  }

  hideTaxes() {
    document.getElementById("invoice_description").value = this.translationsValue["invoice.domestic_reverse_charge_default_note"]

    this.invoiceOutlet.invoicePositionOutlets.forEach((position) => {
      if (position.isTemplate) return

      position.taxFieldTargets.forEach((taxField) => {
        taxField.dataset.oldValue = taxField.value
        if (taxField.matches("select")) {
          const disabledOption = document.createElement("option")
          disabledOption.value = "disabled"
          disabledOption.innerText = this.translationsValue["invoice.select_tax_disabled"]
          taxField.replaceChildren(disabledOption)
        } else {
          taxField.value = "disabled"
        }
      })

      position.calculateSum({ params: { kind: "net" } })
    })
  }

  restoreTaxes(clearDescription = true) {
    if (clearDescription) {
      document.getElementById("invoice_description").value = ""
    }

    if (this.manuallyUnchecked) {
      document.getElementById("invoice_description").value = this.previousDescription
    }

    this.invoiceOutlet.setDefaultTaxes()
    this.invoiceOutlet.invoicePositionOutlets.forEach((position) => {
      if (position.isTemplate) return

      position.taxFieldTargets.forEach((taxField) => {
        const oldValue = taxField.dataset.oldValue
        if (oldValue !== undefined && oldValue !== null) {
          forceSetValueOnSelect(taxField, oldValue)
        } else {
          if (taxField.matches("select")) {
            taxField.value = taxField.options[0].value
          }
        }
        delete taxField.dataset.oldValue
      })
      position.calculateSum({ params: { kind: "net" } })
    })
  }

  /**
   * @param {string} country
   * @returns {boolean}
   */
  countryIsNotPartOfFrenchTerritories(country) {
    return !["FR", "MC", "MQ", "GP", "RE", "GF", "YT", "PM", "WF", "MF", "BL", "NC", "PF"].includes(country)
  }

  /**
   * @returns {Promise<{applicable: true}|{applicable: false, notice: string}>}
   */
  isDesApplicable() {
    return new Promise((resolve) => {
      const resolveNotApplicable = (reason) => resolve({ applicable: false, notice: reason })

      if (document.querySelector("#invoice_buyer_company_false:checked") !== null && this.invoiceOutlet.hasMossOutlet) {
        return
      }

      if (document.getElementById("invoice_seller_country").value.trim() === "") {
        resolveNotApplicable("seller_country_not_set")
        return
      }

      if (document.getElementById("invoice_seller_tax_no").value.trim() === "") {
        resolveNotApplicable("seller_nip_not_set")
        return
      }

      if (document.querySelector("#invoice_buyer_company_true:checked") === null) {
        resolveNotApplicable("buyer_is_not_company")
        return
      }

      const buyerTaxNo = document.getElementById("invoice_buyer_tax_no").value
      if (buyerTaxNo.trim() === "") {
        resolveNotApplicable("buyer_nip_not_set")
        return
      }

      Rails.ajax({
        url: this.invoiceOutlet.viesUrlValue,
        type: "get",
        data: new URLSearchParams({
          tax_no: buyerTaxNo,
          country: document.getElementById("invoice_buyer_country").value,
          timestamp: 0,
        }),
        success: (json) => {
          if (json.valid === null) {
            resolveNotApplicable("undefined_des_applicability")
          } else if (json.valid) {
            const buyerCountryElem = document.getElementById("invoice_buyer_country")
            if (buyerCountryElem.matches("select")) {
              forceSetValueOnSelect(buyerCountryElem, json.country)
            } else {
              buyerCountryElem.value = this.countryCodes[json.country]
            }
            if (!this.countryIsNotPartOfFrenchTerritories(buyerCountryElem.value)) {
              resolveNotApplicable("buyer_from_france")
            } else {
              resolve({ applicable: true })
            }
          } else {
            resolveNotApplicable("tax_no_not_exist")
          }
        },
        error: () => resolveNotApplicable("undefined_des_applicability"),
      })
    })
  }

  setReverseChargeChecked(value) {
    const reverseChargeCheckbox = document.getElementById("invoice_reverse_charge")
    if (reverseChargeCheckbox !== null) {
      reverseChargeCheckbox.checked = value
    }
  }
}
