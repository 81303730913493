import { Controller } from "@hotwired/stimulus"
import { parseNumber, formatNumber, isEmpty, showNotification } from "./helpers"

export default class extends Controller {
  static targets = [
    "priceNet", "priceGross", "priceTax", "expectedMargin", "purchasePriceNet", "purchasePriceGross", "purchasePriceTax", "purchasePriceTax2"
  ]

  static values = {
    priceSetup: Object,
    translations: Object
  }

  getTax(prefix) {
    const tax = this.targets.find(prefix + "Tax")
    if (isNaN(tax.value)) {
      return Number(0)
    }

    return tax.value
  }

  getPriceNetTarget(prefix) {
    return this.targets.find(prefix + "Net")
  }

  getPriceGrossTarget(prefix) {
    return this.targets.find(prefix + "Gross")
  }

  calculatePrice(event) {
    const updateMe = typeof event.params.updateMe === "undefined" ? false : event.params.updateMe
    const tax = parseNumber(this.getTax(event.params.prefix), this.priceSetupValue) // TODO + $(k3+'tax2').parseNumber(OPTS_UNIT, false, true);
    const netTarget = this.getPriceNetTarget(event.params.prefix)
    const grossTarget = this.getPriceGrossTarget(event.params.prefix)
    let net
    let gross
    let purchaseNet
    let expectedMargin

    if (event.params.from === "net" || event.params.from === "tax" || event.params.from === "tax2") {
      //zaczynamy od price_net
      net = parseNumber(netTarget.value, this.priceSetupValue)
      if (updateMe || event.params.from !== "net") {
        netTarget.value = formatNumber(net, this.priceSetupValue)
      }
      grossTarget.value = formatNumber((net * (1 + tax / 100)), this.priceSetupValue)
    } else if (event.params.from === "gross") {
      //zaczynamy od price_gross
      gross = parseNumber(grossTarget.value, this.priceSetupValue)

      netTarget.value = formatNumber((gross / (1 + tax / 100)), this.priceSetupValue)

      if (updateMe) {
        grossTarget.value = formatNumber(gross, this.priceSetupValue)
      }
    } else if (event.params.from === "expected_margin") {
      if (isEmpty(this.expectedMarginTarget.value)) {
        return
      } else if (isEmpty(this.purchasePriceNetTarget.value)) { // nie ma ceny zakupu to ostrzeżenie
        showNotification(this.translationsValue["error_msg"])
        return
      }

      expectedMargin = parseNumber(this.expectedMarginTarget.value, this.priceSetupValue)
      this.expectedMarginTarget.value = formatNumber(expectedMargin, this.priceSetupValue) // od razu ucinamy na formularzu

      purchaseNet = parseNumber(this.purchasePriceNetTarget.value, this.priceSetupValue)

      net = (purchaseNet / (1 - expectedMargin / 100)).toFixed(2) * 1
      net = net === Infinity ? 0 : net
      netTarget.value = formatNumber(net, this.priceSetupValue)

      if (updateMe) { // do dostosowania marży do zaokrąglonych cen
        const newExpectedMargin = net !== 0 ? (net - purchaseNet) / net * 100 : 0
        this.expectedMarginTarget.value = formatNumber(newExpectedMargin, this.priceSetupValue)
      }
      gross = net * (1 + tax / 100)
      grossTarget.value = formatNumber(gross, this.priceSetupValue)
    }

    if (this.hasExpectedMarginTarget) {
      // jak zmieniamy coś w cenie zakupu to niech cena się wyliczy z marży
      if (event.params.prefix.indexOf("purchase") >= 0) {
        // ten sam alert jest wcześniej, ale trzeba tak zrobić, żeby nie pokazać wiele razy tego samego
        if (isEmpty(this.purchasePriceNetTarget.value)) {
          showNotification(this.translationsValue["error_msg"])
        } else {
          this.expectedMarginTarget.dispatchEvent(new Event("change"))
        }
      } else if ((event.target.id.indexOf("product_") >= 0 || event.target.id.indexOf("product_product_warehouses_attributes_") >= 0) &&
          event.params.from !== "expected_margin" && !isEmpty(this.expectedMarginTarget.value)) { // a jak w zwykłych cenach to przeliczamy marżę
        if (isEmpty(this.purchasePriceNetTarget.value)) {
          this.expectedMarginTarget.dispatchEvent(new Event("change"))
        } else if (net > 0) {
          purchaseNet = parseNumber(this.purchasePriceNetTarget.value, this.priceSetupValue)
          this.expectedMarginTarget.value = formatNumber((net - purchaseNet) / net * 100, this.priceSetupValue)
        } else {
          this.expectedMarginTarget.value = ""
        }
      }
    }
  }
}
