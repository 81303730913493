import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    console.log("mask connect")
    this.masks = []
    this.inputTargets.forEach(input => {
      const maskPattern = input.dataset.maskPattern

      if (maskPattern) {
        const mask = IMask(input, { mask: maskPattern, lazy: false })
        const funcOnAccept = input.dataset.funcOnAccept
        if (funcOnAccept) {
          if (typeof window[funcOnAccept] === "function") {
            mask.on("accept", () => {
              window[funcOnAccept]()
            })
          } else {
            console.error(`Funkcja ${funcOnAccept} nie istnieje.`)
          }
        }
        this.masks.push(mask) // Przechowuj maski, aby później je zniszczyć
      } else {
        console.warn(`Brak atrybutu data-mask-pattern dla inputu:`, input)
      }
    })
  }

  disconnect() {
    this.masks.forEach(mask => mask.destroy())
  }
}
