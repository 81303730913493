import { Controller } from "@hotwired/stimulus"
import { each, template } from "lodash"
import { forceSetValueOnSelect, parseNumber, formatNumber } from "./helpers"

export default class extends Controller {
  static targets = ["productId", "positionMore", "buttonLess", "buttonMore", "decidesEmpty", "taxField", "newPosition"]
  static outlets = ["invoice"]

  static values = {
    positionsLimit: { type: Number, default: 10 },
    positionId: Number,
    positionIndex: String,
    positionKind: String
  }

  /** @type {HTMLElement} */
  positionObject = null
  newPositionId
  separatorTemplate
  subtotalAlertTemplate
  prevPositionKind

  get positionsContainer() {
    return this.invoiceOutlet.positionsContainerTarget
  }

  get taxVisible() {
    return this.invoiceOutlet.taxVisibleValue
  }

  get departments() {
    return this.invoiceOutlet.departmentsValue
  }

  get isTemplate() {
    return this.positionObject !== null
  }

  get priceSetup() {
    return this.invoiceOutlet.priceSetupValue
  }

  connect() {
    console.log('invoice-position connect')
    if (document.querySelector("template[data-target='separatorTemplate']")) {
      this.separatorTemplate = template(document.querySelector("template[data-target='separatorTemplate']").innerHTML)
    }
    if (document.querySelector("template[data-target='subtotalAlertTemplate']")) {
      this.subtotalAlertTemplate = template(document.querySelector("template[data-target='subtotalAlertTemplate']").innerHTML)
    }
    if (!this.isTemplate && this.hasNewPositionTarget) {
      this.newPositionId = this.positionIdValue
      this.preparePositionToCopy()
    }
  }

  replaceComma(event) {
    const localeReplacements = {
      pl: { from: ".", to: "," },
      default: { from: ",", to: "." }
    }

    const { from, to } = localeReplacements[this.priceSetup.locale] || localeReplacements["default"]
    event.target.value = event.target.value.replaceAll(from, to)
  }

  /**
   * @param {{ params: { kind: string, quantity?: "to_unit"|"to_total" } }} event
   */
  calculateSum(event) {
    const id = this.positionIndexValue
    let toQuantity = event.params.quantity || "to_total"
    const fromKind = event.params.kind
    const options = {
      field_prefix: this.priceSetup.field_prefix,
      calculating_strategy: {
        position: "default",
        sum: "sum",
        invoice_form_price_kind: "net"
      },
      ignore_tax: false,
    }

    const preprefix = options.field_prefix + id
    const prefix = preprefix + (options.postfix || "")
    const fullPrefix = prefix + (options.price_prefix || "")

    const q = parseNumber(document.querySelector(prefix + "quantity").value, this.priceSetup)
    // zeby nie dzielic przez zero
    if (toQuantity === "to_unit" && q === 0) {
      toQuantity = "to_total"
    }

    const fromQ = toQuantity === "to_unit" ? "total_" : ""

    const fromPriceSelector = fullPrefix + fromQ + "price_" + fromKind
    const fromPriceElem = document.querySelector(fromPriceSelector)
    // szczegolny przypadek - zerujemy, bo zaciagna sie ceny (zakupu) z FIFO
    if (options.set_to_blank_if_blank && fromPriceElem.value === "") {
      ["price_net", "price_gross", "total_price_net", "total_price_gross"].forEach(function (priceKind) {
        document.querySelector(fullPrefix + priceKind).value = ""
      })
      return false
    }

    const fromPrice = parseNumber(fromPriceElem.value, this.priceSetup)
    let tax = this.get(fullPrefix + 'tax'); if (isNaN(tax)) { tax = 0 }
    let tax2 = this.get(fullPrefix + 'tax2'); if (isNaN(tax2)) { tax2 = 0 }

    if (this.priceSetup.ignore_tax === true) {
      tax = tax2 = 0
    }

    // obliczamy ceny
    const prices = this.calculatePrices(fromPrice, q, fromKind, fromQ, tax, tax2)
    each(prices, (key, val) => {
      const opts = { ...this.priceSetup }
      if (val.toString().indexOf("total") > -1) {
        opts.app_digits = 2
      }

      this.set(fullPrefix + val, key, opts)
    })
  }

  get(selector) {
    const element = document.querySelector(selector)
    if (element) {
      return parseNumber(element.value, this.priceSetup)
    }
    return NaN
  }

  set(selector, value, opts) {
    const element = document.querySelector(selector)
    if (element) {
      element.value = formatNumber(value, opts)
    }
    return NaN
  }

  calculatePrices(price, quantity, fromKind, fromQ, tax, tax2) {
    const oppositeKind = { net: 'gross', gross: 'net' }
    const oppositeQ = { total_: '', '': 'total_' }

    const kindConverter = {
      gross: function (priceGross, tax, tax2) { return priceGross / (1 + (tax / 100) + (tax2 / 100)) }, // net_from_gross
      net: function (priceNet, tax, tax2) { return priceNet * (1 + (tax / 100) + (tax2 / 100)) } // gross_from_net
    }

    const qConverter = {
      total_: function (price, q) { return price / q }, // unit_from_total
      "": function (price, q) { return (price * q).toFixed(2) * 1 } // total_from_unit // podobno fix na jakis blad
    }

    tax2 = tax2 || 0
    const fromKindTargetQuantity = qConverter[fromQ](price, quantity)

    const ret = {}
    ret[fromQ + 'price_' + fromKind] = price
    ret[fromQ + 'price_' + oppositeKind[fromKind]] = kindConverter[fromKind](price, tax, tax2)
    ret[oppositeQ[fromQ] + 'price_' + fromKind] = fromKindTargetQuantity // q_converter[from_q](price, quantity)
    ret[oppositeQ[fromQ] + 'price_' + oppositeKind[fromKind]] = kindConverter[fromKind](fromKindTargetQuantity, tax, tax2)

    return ret
  }

  add(event) {
    console.log("add pos " + this.newPositionId + " " + event.params.positionKind)
    this.prevPositionKind = document.getElementById(`invoice_positions_attributes_${this.newPositionId - 1}_kind`)?.value

    if (event.params.positionKind === "text") {
      let newPosition = this.positionObject.outerHTML
      newPosition = this.replaceAll(newPosition, '_' + this.positionIdValue + '_', '_' + this.newPositionId + '_')
      newPosition = this.replaceAll(newPosition, '[' + this.positionIdValue + ']', '[' + this.newPositionId + ']')
      this.positionsContainer.insertAdjacentHTML('beforeend', newPosition)
      this.actionsAfterAddingPosition(this.newPositionId)
    } else {
      const newPosition = this.separatorTemplate({
        key: this.newPositionId,
        kind: event.params.positionKind,
        value: event.params.positionValue
      })
      this.positionsContainer.insertAdjacentHTML('beforeend', newPosition)
      if (event.params.positionKind === "subtotal_with_quantity") {
        this.subtotalWithQuantityAlert()
      }
    }
    this.newPositionId += 1
    this.checkPositionLimit(event.target)
  }

  preparePositionToCopy() {
    const positionTemplate = document.getElementById("detail_" + this.positionIdValue + "_")
    if (!this.positionObject && positionTemplate) {
      this.positionObject = positionTemplate.cloneNode(true)
      this.positionObject.classList.remove("hidden")
      positionTemplate.remove()
    }
  }

  checkPositionLimit(elem) {
    if (this.positionsLimitValue === this.newPositionId) {
      elem.remove()
    }
  }

  actionsAfterAddingPosition(i, options) {
    if (options == null) {
      options = {}
    }

    if (this.taxVisible && this.invoiceOutlet.hasDepartmentSelectTarget) {
      const dep = this.invoiceOutlet.departmentSelectTarget.value
      if (this.departments[dep] && options["set_taxes"] !== false) {
        forceSetValueOnSelect(document.getElementById("invoice_positions_attributes_" + i + "_tax"), this.departments[dep].default_tax)
        document.getElementById("invoice_positions_attributes_" + i + "_tax").dispatchEvent(new Event("change"))
      }
    }

    if (this.invoiceOutlet.hasMossOutlet) {
      const mossController = this.invoiceOutlet.mossOutlet
      if (options['set_taxes'] !== false) {
        if (i > 0) {
          if (this.prevPositionKind === "" && document.getElementById(`invoice_positions_attributes_${i - 1}_tax`).options) {
            document.getElementById(`invoice_positions_attributes_${i}_tax`).replaceChildren(...Array.from(document.getElementById(`invoice_positions_attributes_${i - 1}_tax`).options).map(e => e.cloneNode(true)))
          }
        } else if (mossController.useMossTarget.checked) {
          if (mossController.recipientCountry) {
            mossController.setMossTaxes(mossController.recipientCountry)
          } else {
            mossController.setMossTaxes(this.invoiceOutlet.buyerCountryTarget.value)
          }
        } else {
          mossController.setDefaultTaxes()
        }
      }
    }

    // jesli nietypowa stawka podatku w poprzedniej pozycji to dodajemy ją do selecta nowej %>
    const taxOptions = [...document.querySelectorAll(`#invoice_positions_attributes_${i}_tax > *`)]

    if (document.querySelector(`#invoice_positions_attributes_${i - 1}_tax`) &&
      !taxOptions.map(x => x.value).includes(document.querySelector(`#invoice_positions_attributes_${i - 1}_tax`).value)) {
      const tax = document.querySelector(`#invoice_positions_attributes_${i - 1}_tax`).value
      if (tax !== 'disabled') {
        forceSetValueOnSelect(document.querySelector(`#invoice_positions_attributes_${i}_tax`), tax)
      }
    }

    // TODO dla fr
    // if (dept && (dept['force_tax_rate'] === '1') && !$('#invoice_reverse_charge').is(':checked')){
    //   $('#invoice_positions_attributes_'+(i)+'_tax').val(dept['default_tax'])
    // }

    // fix do ustawiania tax na disabled dla pozycji dodanych po separatorach (page_break, subtotal)
    const positionKind = document.querySelector(`#invoice_positions_attributes_${i - 1}_kind`)?.value

    if (positionKind && !["service_with_products", "services_product"].includes(positionKind)) {
      const elements = Array.from(document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_kind']:not([value])")).reverse().slice(1)
      let indexMatch = elements[0].getAttribute("name").match(/\[(\d+)\]/)
      indexMatch = parseInt(indexMatch[1], 10)
      forceSetValueOnSelect(document.querySelector(`#invoice_positions_attributes_${i}_tax`),
        document.getElementById(`invoice_positions_attributes_${indexMatch}_tax`).value)

      // TODO set_taxes
      //   if(options['set_taxes'] !== false){
      //     $('#invoice_positions_attributes_'+(i)+'_tax').val($('#invoice_positions_attributes_'+(i-index-1)+'_tax').val());
      //     <% if @invoice.tax2_visible? %>
      //       $('#invoice_positions_attributes_'+(i)+'_tax2').val($('#invoice_positions_attributes_'+(i-index-1)+'_tax2').val());
      //     <% end %>
      //   }
      //   return false;
    }

    // TODO lump_sum_taxed
    // if (dept && dept['lump_sum_taxed']) {
    //   if ($('#detail_'+i+'_ .lump_sum_tax:visible').length === 0) {
    //     $('#detail_'+i+'_ .lump_sum_tax').show();
    //     $('#invoice_positions_attributes_'+i+'_lump_sum_tax').prop("disabled", false);
    //   }
    //   if (options['set_taxes'] !== false) {
    //     $('#invoice_positions_attributes_'+i+'_lump_sum_tax').val(dept ? dept['default_lump_sum_tax'] : '');
    //   }
    // } else {
    //   if ($('#detail_'+i+'_ .lump_sum_tax:visible').length > 0) {
    //     $('#detail_'+i+'_ .lump_sum_tax').hide();
    //     $('#detail_'+i+'_ .lump_sum_tax_input').prop("disabled", true);
    //   }
    // }
    if (this.prevPositionKind === "") {
      forceSetValueOnSelect(document.getElementById("invoice_positions_attributes_" + i + "_quantity_unit"),
        document.getElementById("invoice_positions_attributes_" + (i - 1) + "_quantity_unit").value)

      document.getElementById("invoice_positions_attributes_" + i + "_discount_percent").value = document.getElementById("invoice_positions_attributes_" + (i - 1) + "_discount_percent").value
      document.getElementById("invoice_positions_attributes_" + i + "_discount").value = document.getElementById("invoice_positions_attributes_" + (i - 1) + "_discount").value
    }
    // TODO dla korekty
    // handleReverseChargeOnCorrections([i])

    // TODO jpk i korekta
    // var corAfterGtuEl = $("[name = 'invoice[positions_attributes]["+ i +"][correction_after_attributes][gtu_code]']").first();
    // corAfterGtuEl.addClass("gtu-codes")
    // corAfterGtuEl.change(function(){ handlePositionGtuCodesChange(corAfterGtuEl) });
    this.invoiceOutlet.fillWithDefaultTax(i)
  }

  replaceAll(str, from, to) {
    if (from === to) {
      return str
    }
    let idx = str.indexOf(from)
    let j = 0
    while (idx > -1) {
      str = str.replace(from, to)
      idx = str.indexOf(from)
      j++
      if (j > 1000) {
        return str
      }
      //bezpiecznik
    }
    return str
  }

  delete(event) {
    const confirmed = confirm(event.target.getAttribute("data-confirm"))
    if (confirmed) {
      document.getElementById("detail" + this.positionIndexValue).classList.add("hidden")
      document.querySelector('.delete_check_box' + this.positionIndexValue).checked = true
    }
  }

  changeProduct() {
    if (this.hasProductIdTarget && this.productIdTarget.value.length > 0) {
      this.productIdTarget.value = ''
    }
  }

  toggleAdditional() {
    this.positionMoreTargets.forEach((target) => target.classList.toggle("hidden"))
    if (this.buttonLessTarget.classList.contains("hidden")) {
      this.buttonMoreTarget.classList.add("hidden")
      this.buttonLessTarget.classList.remove("hidden")
    } else {
      this.buttonLessTarget.classList.add("hidden")
      this.buttonMoreTarget.classList.remove("hidden")
    }
  }

  isEmpty() {
    return !this.decidesEmptyTargets.some(e => e.value !== "")
  }

  /**
   * @param {HTMLOptionElement[]} options
   * @returns {boolean} czy pozycja była pusta lub znalazł się pasujący podatek
   */
  setTaxOptions(options) {
    const infix = this.invoiceOutlet.correctionValue ? "[correction_after_attributes]" : ""
    const row = this.positionObject === null ? this.element : this.positionObject
    let allTaxesMatched = true
    row.querySelectorAll(`select[name^="invoice[positions_attributes]"][name$='${infix}[tax]']`).forEach((/** @type {HTMLSelectElement} */ taxField) => {
      const tmpValue = taxField.value
      taxField.replaceChildren(...options.map((option) => option.cloneNode(true)))
      taxField.value = tmpValue
      if (taxField.value !== tmpValue) {
        if (row.querySelector(`[name^="invoice[positions_attributes]"][name$='${infix}[total_price_gross]']`).value === "" || row.querySelector(`[name^="invoice[positions_attributes]"][name$='${infix}[total_price_net]']`).value === "") {
          // nic nie zostało wprowadzone więc można zmienić podatek na pierwszą opcję
          taxField.value = taxField.options[0].value
        } else {
          // już coś było wpisane wcześniej więc trzeba dodać nową opcję
          const newOption = document.createElement("option")
          newOption.value = tmpValue
          newOption.innerText = tmpValue
          taxField.appendChild(newOption)
          taxField.value = tmpValue
          allTaxesMatched = false
        }
      }
    })
    return allTaxesMatched
  }

  subtotalWithQuantityAlert() {
    let lastQuantity = null
    let different = false

    document.querySelectorAll("[id^=detail_]").forEach((element) => {
      if (window.getComputedStyle(element).display === "none") {
        return
      }

      const currentKind = element.querySelector("[id$=_kind]").value
      if (currentKind === "text_separator" || currentKind === "page_break") {
        return
      }

      if (currentKind === "subtotal_with_quantity") {
        const name = element.querySelector("[id$=_name]")
        name.parentNode.insertAdjacentHTML('beforeend', this.subtotalAlertTemplate())
        element.querySelector(".subtotal_alert").classList.toggle("hidden", !different)

        lastQuantity = null
        different = false
      } else if (currentKind === "subtotal") {
        lastQuantity = null
        different = false
      } else {
        const currentQuantity = (element.querySelector("[id$=_quantity_unit]").value || "")

        if (lastQuantity === null) {
          lastQuantity = currentQuantity
        } else if (lastQuantity !== currentQuantity) {
          different = true
        }
      }
    })
  }
}
