import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["modal", "modalBodyDefault", "modalBody"]

  show(event) {
    const { isPlanChange, planId, plan } = event.params
    this.modalTarget.classList.toggle("hidden")

    const params = {
      new_plan: planId
    }

    Rails.ajax({
      type: 'post',
      url: '/get_restrictions_modal_content',
      dataType: 'json',
      data: new URLSearchParams(params),
      success: (data) => {
        this.modalBodyDefaultTarget.classList.add("hidden")
        this.modalBodyTarget.innerHTML = data.content
        if (data.status === 'change') {
          if (data.without_payment) {
            if (plan.includes("_free.0")) {
              window.TurboNativeBridge.postMessage("sendEvent", { event: "change_plan_to_micro" })
            }
            this.hide()
            Turbo.cache.clear()
            Turbo.visit("/account/info", { action: "replace" })
          } else {
            window.TurboNativeBridge.postMessage("payment", { plan, apps_id: data.apps_id, is_plan_change: isPlanChange })
          }
        }
      }
    })
  }

  hide() {
    this.modalTarget.classList.toggle("hidden")
    this.modalBodyTarget.innerHTML = ''
    this.modalBodyDefaultTarget.classList.remove("hidden")
  }
}
