import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["examples"]

  connect() {
    document.addEventListener("mousedown", this.handleOutsideClick.bind(this))
  }

  disconnect() {
    document.removeEventListener("mousedown", this.handleOutsideClick.bind(this))
  }

  toggleExamples() {
    this.examplesTarget.classList.toggle("hidden")
  }

  setTemplate(event) {
    const handlebar = event.target.dataset.handlebar
    const css = event.target.dataset.css || handlebar
    const barsTemplate = document.getElementById(`${handlebar}_handlebar`)
    const cssTemplate = document.getElementById(`${css}_css`)

    e1.getDoc().setValue(barsTemplate.value)
    e2.getDoc().setValue(cssTemplate.value)

    this.examplesTarget.classList.add("hidden")
  }

  handleOutsideClick(event) {
    if (this.examplesTarget.contains(event.target)) {
      return
    }

    this.examplesTarget.classList.add("hidden")
  }
}
