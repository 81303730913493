import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { visibleClass: String, hiddenClass: String }
  static targets = ["tabs", "panels"]

  toggleTab(event) {
    this.tabsTargets.forEach((element, index) => {
      if (element === event.target) {
        element.className = this.visibleClassValue

        this.handlePanelVisibility(index)
      } else {
        element.className = this.hiddenClassValue
      }
    })
  }

  handlePanelVisibility(currentIndex) {
    this.panelsTargets.forEach((element, index) => {
      if (currentIndex === index) {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    })
  }
}
