import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  changeSelectedTab(event) {
    if (navigator.userAgent.includes("iOS")) {
      event.preventDefault()
      window.TurboNativeBridge.toggleNavBar()
      window.TurboNativeBridge.postMessage("changeSelectedTab", { index: event.params.index })
    }
  }
}
