import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ['open', 'closed', 'body']
  static values = {
    inputId: String
  }

  connect() {
    console.log('expansion connect1 v:', this.inputIdValue)

    if (document.getElementById(this.inputIdValue).value === '0') {
      console.log('0')
      this.bodyTarget.classList.add('hidden')
      this.closedTarget.style.display = 'none'
      this.openTarget.style.display = 'block'
      useTransition(this, { element: this.bodyTarget, transitioned: false })
    } else {
      console.log('1')
      this.bodyTarget.classList.remove('hidden')
      this.openTarget.style.display = 'none'
      this.closedTarget.style.display = 'block'
      useTransition(this, { element: this.bodyTarget, transitioned: true })
    }
    // this.bodyTarget.classList.add('hidden')
  }

  open() {
    console.log('open1', this)
    // this.toggleTransition() // stimulus-use
    // this.bodyTarget.style.display = 'block'
    this.toggleTransition()
    this.openTarget.style.display = 'none'
    this.closedTarget.style.display = 'block'
    document.getElementById(this.inputIdValue).value = '1'
  }

  close() {
    console.log('close1')
    this.leave() // stimulus-use
    // this.bodyTarget.style.display = 'none'
    this.closedTarget.style.display = 'none'
    this.openTarget.style.display = 'block'
    document.getElementById(this.inputIdValue).value = '0'
  }
}
