import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"
import { template, debounce } from "lodash"
import { forceSetValueOnSelect } from "./helpers"

export default class extends Controller {
  static targets = ["container", "input", "list", "product", "resourceTemplate"]
  static outlets = ["invoice"]

  eventCreated = false
  productsData = []

  connect() {
    this.containerTarget.classList.add('hidden')
    this.resourceTemplate = template(this.resourceTemplateTarget.innerHTML)
    useTransition(this, { element: this.containerTarget })
  }

  open() {
    this.toggle()
    this.search()
  }

  initialize() {
    this.triggerSearch = debounce(this.triggerSearch, 1000).bind(this)
  }

  triggerSearch() {
    this.search()
  }

  search() {
    var input
    input = this.inputTarget.value

    if (input.length === 0) {
      input = "*"
    }

    document.getElementById('blank_product').classList.add('hidden')
    this.listTarget.classList.remove('hidden')
    const params = {
      rows: 10,
      kind: 'sell',
      name: input
    }
    if (this.hasInvoiceOutlet) {
      if (this.invoiceOutlet.warehouseIdValue !== 0) {
        params["warehouse_id"] = this.invoiceOutlet.warehouseIdValue
      }
      if (this.invoiceOutlet.hasPriceListTarget) {
        params["price_list_id"] = this.invoiceOutlet.priceListTarget.value
      }
    }

    Rails.ajax({
      url: "/products/find",
      type: 'post',
      data: new URLSearchParams(params),
      success: (data) => {
        this.listTarget.innerHTML = ''
        if (data.rows.length > 0) {
          this.productsData = data.rows
          data.rows.forEach((product, index) => {
            this.itemData = {
              key: index,
              label: product.label
            }
            this.listTarget.innerHTML += this.resourceTemplate(this.itemData)
          })
        } else {
          this.listTarget.classList.add('hidden')
          document.getElementById('blank_product').classList.remove('hidden')
        }
      }
    })
  }

  select(event) {
    event.preventDefault()
    const productIndex = event.params.value
    const productElemIndex = this.productTarget.dataset.row
    const prefix = "invoice_positions_attributes" + productElemIndex
    let showMore = false

    this.productTarget.value = this.productsData[productIndex].value
    document.getElementById(prefix + "product_id").value = this.productsData[productIndex].id
    document.getElementById(prefix + "quantity").value = this.productsData[productIndex].quantity

    if (this.productsData[productIndex].quantity_unit && document.getElementById(prefix + "quantity_unit")) {
      forceSetValueOnSelect(document.getElementById(prefix + "quantity_unit"), this.productsData[productIndex].quantity_unit)
    }

    if (document.getElementById(prefix + "price_net")) {
      document.getElementById(prefix + "price_net").value = this.productsData[productIndex].price_net
    } else if (document.getElementById(prefix + "total_price_gross")) {
      document.getElementById(prefix + "total_price_gross").value = this.productsData[productIndex].price_gross
    }

    if (this.invoiceOutlet.taxVisibleValue && !["wdt", "export_products"].includes(this.invoiceOutlet.kindValue)) {
      if (this.invoiceOutlet.autocompleteTax) {
        this.invoiceOutlet.setDefaultTax()
        const itemTax = this.invoiceOutlet.defaultTax || this.productsData[productIndex].tax
        forceSetValueOnSelect(document.getElementById(prefix + "tax"), itemTax)
      }
    } else if (!this.invoiceOutlet.taxVisibleValue && this.invoiceOutlet.accountCountryValue === "fr") {
      forceSetValueOnSelect(document.getElementById(prefix + "tax"), "disabled")
    }

    if (this.productsData[productIndex].description?.length > 0 && document.getElementById(prefix + "description")) {
      document.getElementById(prefix + "description").value = this.productsData[productIndex].description
      showMore = true
    }

    if (this.productsData[productIndex].code?.length > 0 && document.getElementById(prefix + "code")) {
      document.getElementById(prefix + "code").value = this.productsData[productIndex].code
      showMore = true
    }

    if (this.productsData[productIndex].gtu_codes?.length > 0 && document.getElementById(prefix + "gtu_code")?.length > 0) {
      document.getElementById(prefix + "gtu_code").value = this.productsData[productIndex].gtu_codes[0]
      showMore = true
    }

    if (this.productsData[productIndex].additional_info?.length > 0 && document.getElementById(prefix + "additional_info")) {
      document.getElementById(prefix + "additional_info").value = this.productsData[productIndex].additional_info
      showMore = true
    }

    if (showMore) {
      document.querySelector("[data-toggle-position-button]").dispatchEvent(new Event("click"))
    }

    if (document.getElementById(prefix + "price_net")) {
      document.getElementById(prefix + "price_net").dispatchEvent(new Event("change"))
    }

    if (this.invoiceOutlet.handleCondNoteValue) {
      this.invoiceOutlet.updateCondNote()
    }

    this.toggle()
  }

  add() {
    this.productTarget.value = this.inputTarget.value
    this.toggle()
  }

  toggle() {
    this.toggleTransition()
    // https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710
    if (this.eventCreated === false) {
      this.inputTarget.value = ''
      this.inputTarget.focus()
    } else {
      this.removeEvent()
    }
  }

  hide() {
    this.leave()
    this.removeEvent()
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      document.removeEventListener("click", this.eventCreated)
      this.eventCreated = false
    }
  }

  disconnect() {
    this.removeEvent()
  }
}
