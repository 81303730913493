import ModalController from "./modal_controller"
import Rails from "@rails/ujs"

export default class extends ModalController {
  static targets = ["defaultTitle", "errorTitle", "errorBody", "loaderBody", "modalBody"]

  open(event) {
    this.resetViews()

    super.open()

    Rails.ajax({
      url: "/get_restrictions_modal_content",
      type: 'get',
      dataType: 'script',
      data: new URLSearchParams({ new_plan: event.params.newPlan, force_payment: event.params.forcePayment }),
      success: (data) => {
        this.modalBodyTarget.innerHTML = new XMLSerializer().serializeToString(data)

        this.showContentBody()

        const redirect = data.getElementsByTagName("div")[0].getAttribute('data-redirect')

        if (redirect && redirect !== "") {
          window.location.href = redirect
        }
      },
      error: () => {
        this.showError()
      }
    })
  }

  resetViews() {
    this.errorTitleTarget.classList.add('hidden')
    this.errorBodyTarget.classList.add('hidden')
    this.modalBodyTarget.classList.add('hidden')

    this.defaultTitleTarget.classList.remove('hidden')
    this.loaderBodyTarget.classList.remove('hidden')
  }

  showError() {
    this.modalBodyTarget.classList.add('hidden')
    this.defaultTitleTarget.classList.add('hidden')
    this.loaderBodyTarget.classList.add('hidden')

    this.errorTitleTarget.classList.remove('hidden')
    this.errorBodyTarget.classList.remove('hidden')
  }

  showContentBody() {
    this.loaderBodyTarget.classList.add('hidden')
    this.errorTitleTarget.classList.add('hidden')
    this.errorBodyTarget.classList.add('hidden')

    this.modalBodyTarget.classList.remove('hidden')
    this.defaultTitleTarget.classList.remove('hidden')
  }
}
