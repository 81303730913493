import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ["icon", "popover", "title", "description", "link"]
  static values = { url: String }

  connect() {
    this.loaded = false
    this.popper = null
    this.windowListener = null
  }

  disconnect() {
    this.removeWindowListener()
  }

  async load() {
    if (this.loaded) {
      return
    }

    const data = await new Promise((resolve, reject) => {
      const callbackName = `helplink_${Math.random().toString(36).substring(2)}`
      const script = document.createElement('script')
      script.addEventListener('error', (ev) => reject(ev.error))
      window[callbackName] = (response) => {
        resolve(response)
        delete window[callbackName]
        script.remove()
      }
      const params = new URLSearchParams({ callback: callbackName })
      script.src = this.urlValue + ".js?" + params.toString()
      this.iconTarget.after(script)
    })
    this.descriptionTarget.innerHTML = data.help_content
    this.titleTarget.innerText = data.title
    this.linkTarget.href = data.url
    this.loaded = true
    if (this.popper !== null) {
      // popperowi zmieniła się zawartość po jego wyświetleniu
      // więc może wystawać poza ekran i trzeba to naprawić
      this.popper.update()
    }
  }

  toggle() {
    if (this.popper === null) {
      this.load()
      this.popper = createPopper(this.iconTarget, this.popoverTarget, { placement: "bottom" })
      this.popoverTarget.classList.remove("hidden")
      this.addWindowListener()
    } else {
      this.popoverTarget.classList.add("hidden")
      this.popper.destroy()
      this.popper = null
      this.removeWindowListener()
    }
  }

  addWindowListener() {
    this.debounce = true
    this.windowListener = () => {
      if (this.debounce) {
        this.debounce = false
      } else {
        this.toggle()
      }
    }
    window.addEventListener("click", this.windowListener)
  }

  removeWindowListener() {
    if (this.windowListener !== null) {
      window.removeEventListener("click", this.windowListener)
      this.windowListener = null
    }
  }
}
