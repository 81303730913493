import { Controller } from "@hotwired/stimulus"
import { showNotification } from "./helpers"

export default class extends Controller {
  static targets = ["useAutoPayments", "useBanking", "warehouse"]

  static values = {
    translations: Object
  }

  notifyAndFixAutoPayments() {
    if (this.useAutoPaymentsTarget.checked === true && this.useBankingTarget.checked === false) {
      showNotification(this.translationsValue["turning_off_banking_requires_turning_off_autopayments"])
      this.useAutoPaymentsTarget.checked = false
    }
  }

  notifyAndFixBanking() {
    if (this.useAutoPaymentsTarget.checked === true && this.useBankingTarget.checked === false) {
      showNotification(this.translationsValue["turning_on_autopayments_requires_turning_on_banking"])
      this.useBankingTarget.checked = true
    }
  }

  checkWarehouseActivation(event) {
    const checkbox = event.target
    const productCount = JSON.parse(this.warehouseTarget.dataset.productCount)
    const confirmMessage = this.warehouseTarget.dataset.confirmMessage
    const confirmMessageWithDisabledProducts = this.warehouseTarget.dataset.confirmWithDisabledProducts
    const productLimit = JSON.parse(this.warehouseTarget.dataset.productLimit)
    const productLimitDisabledProducts = JSON.parse(this.warehouseTarget.dataset.productDisabledCount)

    if (checkbox.checked) {
      if (productCount > productLimit) {
        let userConfirmed
        if (productLimitDisabledProducts > 0) {
          userConfirmed = confirm(confirmMessageWithDisabledProducts)
        } else {
          userConfirmed = confirm(confirmMessage)
        }

        if (!userConfirmed) {
          checkbox.checked = false
        }
      }
    }
  }
}
