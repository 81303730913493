import { Controller } from '@hotwired/stimulus'
import { template, escape } from "lodash"

export default class extends Controller {
  static targets = ["progressTemplate", "completedTemplate", "showAttachments"]

  takenNames = []
  pluploadRun = false

  connect() {
    console.log("uploader run")
    this.progressTemplate = template(this.progressTemplateTarget.innerHTML)
    this.completedTemplate = template(this.completedTemplateTarget.innerHTML)
  }

  upload() {
    if (!this.pluploadRun) {
      this.pluploadInit()
      document.querySelector('#container_attachment input[type=file]').click()
    }
  }

  pluploadInit() {
    const config = {
      browse_button: 'pick-files',
      container: 'container_attachment',
      drop_element: 'container_attachment',
      preinit: {
        Init: () => {
          this.pluploadRun = true
        }
      },
      init: {
        FilesAdded: (up, files) => {
          for (var i = 0; i < files.length; i++) {
            this.itemData = {
              id: files[i].id,
              name: escape(files[i].name) + ' (' + plupload.formatSize(files[i].size) + ')'
            }
            document.getElementById('file_list').insertAdjacentHTML('beforeend', this.progressTemplate(this.itemData))
          }
          up.start()
        },
        UploadProgress: function(up, file) {
          document.querySelector("#file-" + file.id + " b").innerHTML = file.percent + '%'
        },
        FileUploaded: (up, file) => {
          this.itemData = {
            id: file.id,
            url: S3UploadKey + encodeURIComponent(file.name),
            name: escape(file.name)
          }
          document.getElementById('file_list').insertAdjacentHTML('beforeend', this.completedTemplate(this.itemData))
          document.querySelector("#file-" + file.id).remove()
          this.showAttachmentsTarget.classList.remove("hidden")
        },
        Error: function(up, err) {
          console.log("\nError #" + err.code + ": " + err.message)
          document.getElementById('file_list').innerText = "Wystąpił błąd"
        },
        BeforeUpload: (up, file) => {
          file.name = this.patchName(file.name)
          up.settings.multipart_params.key = S3UploadKey + file.name
          up.settings.multipart_params.Filename = file.name
        }
      }
    }
    this.uploader = new plupload.Uploader(Object.assign(config, uploaderSetup))
    this.uploader.init()
  }

  patchName(fileName) {
    var newName = fileName
    var i = 2
    while (this.takenNames.includes(newName)) {
      newName = i + "_" + fileName
      ++i
    }
    this.takenNames.push(newName)
    return newName
  }
}
