import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["currency", "allCurrencies"]

  connect() {
    console.log('currency connect', this.element)
  }

  handleMoreCurrencies(event) {
    if (this.hasAllCurrenciesTarget && this.currencyTarget.value === "more") {
      const defaultValueFromAll = this.allCurrenciesTarget.value
      this.currencyTarget.replaceChildren(...this.allCurrenciesTarget.children)
      this.allCurrenciesTarget.remove()
      this.currencyTarget.value = defaultValueFromAll
    } else {
      event.currentTarget.dispatchEvent(new Event("update"))
    }
  }
}
