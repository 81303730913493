import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"
import { template } from "lodash"

export default class extends Controller {
  static targets = ["container", "input", "resourceList", "resourceTemplate", "resultTemplate", "resultTemplateCorrection", "blank", "button", "invoices"]
  static outlets = ["invoice"]
  static values = {
    documentsKind: String,
    excluded: Array,
    income: Boolean,
    url: String,
    unoccupied: String,
    onlyOneEstimate: Boolean,
    plAdvances: String,
  }

  eventCreated = false
  timeout = null
  lastRequestTimestamp = null
  lastValue = null
  resourceTemplate
  resultTemplate
  resultTemplateCorrection

  connect() {
    if (this.hasResourceTemplateTarget) {
      this.resourceTemplate = template(this.resourceTemplateTarget.innerHTML)
    }
    if (this.hasResultTemplateTarget) {
      this.resultTemplate = template(this.resultTemplateTarget.innerHTML)
    }
    if (this.hasResultTemplateCorrectionTarget) {
      this.resultTemplateCorrection = template(this.resultTemplateCorrectionTarget.innerHTML)
    }
    useTransition(this, { element: this.containerTarget })
  }

  open() {
    this.toggle()
    this.search()
  }

  triggerSearch() {
    if (this.lastValue === this.inputTarget.value) {
      return
    }

    this.lastValue = this.inputTarget.value

    if (this.timeout !== null) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.search()
    }, 500)
  }

  search() {
    const requestTimestamp = this.lastRequestTimestamp = Date.now()
    this.blankTarget.classList.add("hidden")
    this.resourceListTarget.classList.remove("hidden")

    const params = new URLSearchParams({
      rows: 10,
      kind: this.documentsKindValue,
      name: this.inputTarget.value,
      income: this.invoiceOutlet.incomeValue,
      include_kind: 1,
      display_only_unoccupied: this.unoccupiedValue,
      with_corrections: this.plAdvancesValue
    })
    this.excludedValue.forEach(id => {
      params.append("exclude_ids[]", id)
    })

    Rails.ajax({
      url: this.urlValue,
      type: "get",
      data: params,
      success: (data) => {
        if (requestTimestamp !== this.lastRequestTimestamp) {
          return
        }

        this.resourceListTarget.innerHTML = ""
        if (data.length > 0) {
          data.forEach((invoice) => {
            this.itemData = {
              id: invoice.id,
              label: invoice.label,
              number: invoice.number,
              issue_date: this.documentsKindValue === "advance" ? invoice.order_date : invoice.issue_date,
              price: invoice.price_with_currency,
              corrections: "corrections" in invoice ? JSON.stringify(invoice.corrections).replaceAll("\"", "'") : "[]"
            }
            this.resourceListTarget.innerHTML += this.resourceTemplate(this.itemData)
          })
        } else {
          this.resourceListTarget.classList.add("hidden")
          this.blankTarget.classList.remove("hidden")
        }
      }
    })
  }

  select(event) {
    event.preventDefault()
    document.querySelector("[data-target~='emptyList']")?.classList.add("hidden")
    document.querySelector("[data-target~='invoicesList']")
      .insertAdjacentHTML("beforeend", this.resultTemplate({ id: event.params.id, label: event.params.label }))
    if (typeof event.params.corrections === 'string' || event.params.corrections instanceof String) {
      JSON.parse(event.params.corrections.replaceAll("'", "\""))
        .forEach((element) => {
          document.querySelector("[data-target~='invoicesList']")
            .insertAdjacentHTML("beforeend", this.resultTemplateCorrection({ number: element.number, price_with_currency: element.price_with_currency, issue_date: element.issue_date }))
        }, this)
    }
    if (this.onlyOneEstimateValue) {
      this.excludedValue = [event.params.id]
      this.toogleOtherEstimates(true, event.params.id)
    } else {
      this.excludedValue = [...this.excludedValue, event.params.id]
    }
    this.toggle()
  }

  checkEstimates(event) {
    if (event.target.checked) {
      this.toogleOtherEstimates(true, parseInt(event.target.value))
    } else {
      this.toogleOtherEstimates(false)
    }
  }

  toogleOtherEstimates(hide = true, selectedElemId = null) {
    if (hide) {
      if (selectedElemId) {
        this.invoicesTargets.forEach((element) => {
          if (parseInt(element.querySelector("input[type='checkbox']")?.value) !== selectedElemId) {
            element.remove()
          }
        })
        this.excludedValue = [selectedElemId]
      }
      this.buttonTarget.classList.add("hidden")
    } else {
      this.buttonTarget.classList.remove("hidden")
    }
  }

  toggle() {
    this.toggleTransition()
    // https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      this.inputTarget.value = ""
      this.inputTarget.focus()
      document.addEventListener("click", fn)
    } else {
      this.removeEvent()
    }
  }

  hide() {
    // console.log('hide1', this.element, event.target)
    this.leave()
    this.removeEvent()
  }

  outsideHide(event) {
    // this.hide(event)
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains("hidden")) {
      this.leave()
      this.removeEvent()
    }
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      document.removeEventListener("click", this.eventCreated)
      this.eventCreated = false
    }
  }

  disconnect() {
    this.removeEvent()
  }
}
