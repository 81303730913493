import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["dark", "active"]
  storageEventHandler = null
  darkMediaQuery = null
  mediaEventHandler = null

  localStorageKey = "fakturownia-tailwind-theme"

  connect() {
    this.storageEventHandler = (ev) => {
      if (ev.key === this.localStorageKey || ev.key === null) {
        // inna karta zmieniła motyw aplikacji
        this.syncTheme(false)
      }
    }
    window.addEventListener("storage", this.storageEventHandler)

    this.mediaEventHandler = () => {
      // użytkownik zmienił kolory systemowe
      this.syncTheme(false)
    }
    this.darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    this.darkMediaQuery.addEventListener("change", this.mediaEventHandler)

    this.syncTheme(false)
  }

  disconnect() {
    window.removeEventListener("storage", this.storageEventHandler)
    this.darkMediaQuery.removeEventListener("change", this.mediaEventHandler)
  }

  setSystem() {
    window.localStorage.removeItem(this.localStorageKey)
    this.syncTheme(true)
  }

  setLight() {
    window.localStorage.setItem(this.localStorageKey, 'light')
    this.syncTheme(true)
  }

  setDark() {
    window.localStorage.setItem(this.localStorageKey, 'dark')
    this.syncTheme(true)
  }

  syncTheme(fromSettingChange) {
    const storedValue = window.localStorage.getItem(this.localStorageKey)
    const isDark = storedValue === 'dark' || (storedValue === null && this.darkMediaQuery.matches)
    const theme = storedValue === null ? "system" : storedValue

    if (isDark) {
      this.element.classList.add(this.darkClass)
    } else {
      this.element.classList.remove(this.darkClass)
    }
    if (navigator.userAgent.includes("Turbo Native")) {
      window.TurboNativeBridge.postMessage("setTheme", { isDark, fromSettingChange, theme })
    }

    this.element.querySelectorAll("button[data-theme]").forEach(btn => btn.classList.remove(this.activeClass))
    this.element.querySelector(`[data-theme="${theme}"]`)?.classList.add(this.activeClass)
  }
}
