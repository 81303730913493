import { Controller } from "@hotwired/stimulus"
import { showNotification } from "./helpers"

export default class extends Controller {
  connect() {
    console.log("turbo-app connect")
  }

  clearCache() {
    Turbo.cache.clear()
  }

  /** @param {Event & {type: "change", detail: {checked: boolean}}} event */
  changeBiometricLock(event) {
    const enabled = event.detail.checked
    window.TurboNativeBridge.postMessage("changeBiometricLock", { enabled })
    document.cookie = `mobile_biometric_lock=${enabled | 0}; SameSite=Strict`
  }

  changeBiometricSignin(event) {
    const enabled = event.detail.checked
    window.TurboNativeBridge.postMessage("changeBiometricSignin", { enabled })
  }

  sendQRCode(event) {
    const code = event.params.code
    window.TurboNativeBridge.postMessage("sendQRCode", { code })
  }

  share(event) {
    const message = event.params.message
    const type = event.params.type || "text"
    window.TurboNativeBridge.postMessage("share", { type, message })
  }

  sendReview() {
    window.TurboNativeBridge.postMessage("sendReview")
  }

  connectAccount(event) {
    if (navigator.userAgent.includes("Turbo Native")) {
      event.preventDefault()
      const provider = event.params.provider
      window.TurboNativeBridge.postMessage("connectAccount", { provider })
    }
  }

  showMessage(event) {
    showNotification(event.params.message)
  }
}
