import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'
import Rails from "@rails/ujs"
import { template, debounce } from "lodash"
import { forceSetValueOnSelect } from "./helpers"

export default class extends Controller {
  static targets = ["menu", "input", "list", "resourceTemplate", "blank"]
  static values = {
    company: Boolean,
    kind: String,
    type: String,
    useRecipient: Boolean,
    income: Boolean,
    translations: Object,
    identityCardFields: Array,
    index: { type: Number, default: 0 },
    buyerCompanyChoosable: Boolean,
    prefix: String,
  }

  static outlets = ["invoice", "delivery-address"]

  eventCreated = false
  clientData = []

  connect() {
    this.menuTarget.classList.add('hidden')
    this.resourceTemplate = template(this.resourceTemplateTarget.innerHTML)

    useTransition(this, { element: this.menuTarget })
  }

  initialize() {
    this.triggerSearch = debounce(this.triggerSearch, 1000).bind(this)
  }

  open() {
    this.toggle()
    this.search()
  }

  triggerSearch() {
    this.search()
  }

  search() {
    this.blankTarget.classList.add("hidden")
    this.listTarget.classList.remove('hidden')

    const params = {
      rows: 10,
      kind: this.kindValue,
      name: this.inputTarget.value
    }

    if (this.buyerCompanyChoosableValue) {
      params.person = !this.companyValue
    }

    Rails.ajax({
      url: "/clients/find",
      type: 'post',
      data: new URLSearchParams(params),
      success: (data) => {
        this.listTarget.innerHTML = ''
        if (data.length > 0) {
          this.clientData = data
          data.forEach((client, index) => {
            this.itemData = {
              key: index,
              client: client.label
            }
            this.listTarget.innerHTML += this.resourceTemplate(this.itemData)
          })
        } else {
          this.listTarget.classList.add('hidden')
          this.blankTarget.classList.remove('hidden')
        }
      }
    })
  }

  select(event) {
    event.preventDefault()
    const clientIndex = event.params.value

    switch (this.typeValue) {
      case "recipient":
        if (this.clientData[clientIndex].buyer !== undefined) {
          if (confirm(this.translationsValue['fetch_buyer_confirm'] + this.clientData[clientIndex].buyer.label + '?')) {
            document.querySelector("#invoice_buyer_name").value = this.clientData[clientIndex].buyer.value
            this.selectType(this.clientData[clientIndex].buyer, "buyer")
            this.fillBuyerFields(this.clientData[clientIndex].buyer)
          }
        }
        this.fillRecipientFields(this.clientData[clientIndex])
        break
      case "buyer":
        if ((this.incomeValue && this.useRecipientValue)) {
          if (this.clientData[clientIndex].buyer !== undefined && confirm(this.translationsValue['fetch_buyer_confirm'] + this.clientData[clientIndex].buyer.label + '?')) {
            this.indexValue = parseInt(document.querySelector("[data-target='buttonRecipient']").dataset.invoiceContractorsIndexValue)
            document.querySelector("[data-target='buttonRecipient'] > button").click()
            this.fillRecipientFields(this.clientData[clientIndex])
            document.querySelector("#invoice_buyer_name").value = this.clientData[clientIndex].buyer.value
            this.fillBuyerFields(this.clientData[clientIndex].buyer)
          } else {
            this.fillBuyerFields(this.clientData[clientIndex])
          }
        } else {
          this.fillBuyerFields(this.clientData[clientIndex])
        }
        break
      case "client":
        this.fillClientFields(this.clientData[clientIndex])
        break
      default:
        throw new Error(`unknown type: ${this.typeValue}`)
    }

    this.hide()
  }

  fillBuyerFields(buyer) {
    document.getElementById("invoice_client_id").value = buyer.id
    document.getElementById("invoice_buyer_tax_no").value = buyer.tax_no
    document.getElementById("invoice_buyer_post_code").value = buyer.post_code
    document.getElementById("invoice_buyer_city").value = buyer.city
    document.getElementById("invoice_buyer_street").value = buyer.street

    forceSetValueOnSelect(document.getElementById("invoice_buyer_country"), buyer.country)

    if (this.hasInvoiceOutlet && this.invoiceOutlet.kindValue !== "kp" &&
      document.getElementById("invoice_buyer_person")) {
      document.getElementById("invoice_buyer_person").value = buyer.person
    }

    document.getElementById("invoice_buyer_email").value = buyer.email
    document.getElementById("invoice_buyer_phone").value = buyer.phone
    document.getElementById("invoice_buyer_mobile_phone").value = buyer.mobile_phone
    if (buyer.note) {
      document.getElementById("invoice_buyer_note").value = buyer.note
    }

    if (document.getElementById("invoice_buyer_tax_no_kind")) {
      document.getElementById("invoice_buyer_tax_no_kind").value = buyer.tax_no_kind
    }

    document.getElementById("invoice_buyer_name").value = buyer.value
    if (document.getElementById("invoice_buyer_company")) {
      document.getElementById("invoice_buyer_company").value = buyer.company
    } else {
      if (document.getElementById("invoice_buyer_title")) {
        document.getElementById("invoice_buyer_title").value = buyer.title
      }
      document.getElementById("invoice_buyer_first_name").value = buyer.first_name
      document.getElementById("invoice_buyer_last_name").value = buyer.last_name
    }

    if (this.hasInvoiceOutlet && this.invoiceOutlet.accountUseMassPaymentValue) {
      document.getElementById("invoice_buyer_mass_payment_code").value = buyer.mass_payment_code
      document.getElementById("invoice_buyer_mass_payment_code").dispatchEvent(new Event("change"))
    }

    if (document.getElementById("invoice_buyer_bank")) {
      document.getElementById("invoice_buyer_bank").value = buyer.bank
      document.getElementById("invoice_buyer_bank_account").value = buyer.bank_account
    }

    if (this.hasDeliveryAddressOutlet && this.deliveryAddressOutlet.hasSelectTarget) {
      // multiple_delivery_addresses
      this.deliveryAddressOutlet.loadDeliveryAddresses(buyer.id)
    } else if (buyer.use_delivery_address && document.getElementById("invoice_use_delivery_address")) {
      document.getElementById("invoice_use_delivery_address").checked = buyer.use_delivery_address
      document.getElementById("invoice_use_delivery_address").dispatchEvent(new Event("change"))
      document.getElementById("invoice_delivery_address").value = buyer.delivery_address
    }

    if (buyer.discount != null) {
      document.querySelector("[data-invoice-target='discount']").checked = true
      document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_discount_percent']").forEach((elem) => {
        elem.value = buyer.discount
      })
    }

    if (buyer.default_payment_type !== null && buyer.default_payment_type !== '') {
      forceSetValueOnSelect(document.getElementById("invoice_payment_type"), buyer.default_payment_type)
    }
    if (buyer.payment_to_kind !== null && buyer.payment_to_kind !== '') {
      forceSetValueOnSelect(document.getElementById("invoice_payment_to_kind"), buyer.payment_to_kind)
    }

    this.defaultTax = buyer.default_tax

    // vat_rr
    for (const field of this.identityCardFieldsValue) {
      document.getElementById(`invoice_buyer_${field}`).value = buyer[field]
    }

    if (this.invoiceOutlet && this.invoiceOutlet.usePriceListValue) {
      this.invoiceOutlet.priceListTarget.value = buyer.price_list_id
    }

    if (this.hasInvoiceOutlet && this.invoiceOutlet.handleCondNoteValue) {
      this.invoiceOutlet.updateCondNote()
    }

    //TODO if @invoice.jpk_pds_enabled? - start
    // if (client.tp_client_connection) {
    //   addInvoiceProcedureDesignation('TP');
    //   $.jGrowl("Klient powiązany z nabywcą - automatyczne dodanie kodu TP");
    // } else {
    //   handleRemovingTP();
    //   if(isPdCodePresent('TP')) {
    //     $.jGrowl("Zmiana klienta - automatyczne usunięcie kodu TP");
    //   }
    // }
    //TODO if @invoice.jpk_pds_enabled? - end

    this.invoiceOutlet?.fillWithDefaultTax()
  }

  fillRecipientFields(recipient) {
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_client_id`).value = recipient.id
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_tax_no`).value = recipient.tax_no
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_post_code`).value = recipient.post_code
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_city`).value = recipient.city
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_street`).value = recipient.street
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_country`).value = recipient.country
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_email`).value = recipient.email
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_phone`).value = recipient.phone
    document.getElementById(`invoice_recipients_attributes_${this.indexValue}_name`).value = recipient.value
    if (!this.companyValue) {
      document.getElementById(`invoice_recipients_attributes_${this.indexValue}_first_name`).value = recipient.first_name
      document.getElementById(`invoice_recipients_attributes_${this.indexValue}_last_name`).value = recipient.last_name
    }

    if (recipient.note) {
      document.getElementById(`invoice_recipients_attributes_${this.indexValue}_note`).value = recipient.note
    }

    setTimeout(() => {
      this.selectType(recipient, `recipients_attributes_${this.indexValue}`)
    }, 20)

    //TODO
    // <% if current_account.gov_enabled? %>
    // pos.find("[id$='_role']").val(client.role || "<%= Addresses::Recipient::ROLES.first %>").trigger("change");
    // pos.find("[id$='_participation']").val(client.participation);
    // <% end %>
  }

  fillClientFields(client) {
    document.getElementById(`${this.prefixValue}_client_id`).value = client.id
    document.getElementById(`${this.prefixValue}_client_tax_no`).value = client.tax_no
    document.getElementById(`${this.prefixValue}_client_post_code`).value = client.post_code
    document.getElementById(`${this.prefixValue}_client_city`).value = client.city
    document.getElementById(`${this.prefixValue}_client_street`).value = client.street
    document.getElementById(`${this.prefixValue}_client_email`).value = client.email
    document.getElementById(`${this.prefixValue}_client_phone`).value = client.phone
    forceSetValueOnSelect(document.getElementById(`${this.prefixValue}_client_country`), client.country)

    document.getElementById(`${this.prefixValue}_client_name`).value = client.value
    if (document.getElementById(`${this.prefixValue}_client_title`)) {
      document.getElementById(`${this.prefixValue}_client_title`).value = client.title
    }
    document.getElementById(`${this.prefixValue}_client_first_name`).value = client.first_name
    document.getElementById(`${this.prefixValue}_client_last_name`).value = client.last_name
    if (document.getElementById(`${this.prefixValue}_client_title`)) {
      forceSetValueOnSelect(document.getElementById(`${this.prefixValue}_client_title`), client.title)
    }

    if (this.hasDeliveryAddressOutlet && this.deliveryAddressOutlet.hasSelectTarget) {
      this.deliveryAddressOutlet.loadDeliveryAddresses(client.id)
    } else if (client.use_delivery_address && document.getElementById(`${this.prefixValue}_client_use_delivery_address`)) {
      document.getElementById(`${this.prefixValue}_client_use_delivery_address`).checked = client.use_delivery_address
      document.getElementById(`${this.prefixValue}_client_use_delivery_address`).dispatchEvent(new Event("change"))
      document.getElementById(`${this.prefixValue}_client_delivery_address`).value = client.delivery_address
    }
  }

  selectType(obj, type) {
    if (this.buyerCompanyChoosableValue) {
      if (obj.company) {
        document.getElementById(`invoice_${type}_company_true`).click()
      } else {
        document.getElementById(`invoice_${type}_company_false`).click()
      }
    }
  }

  toggle() {
    this.toggleTransition()
    // https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      this.inputTarget.value = ''
      this.inputTarget.focus()
      document.addEventListener("click", fn)
    } else {
      this.removeEvent()
    }
  }

  hide() {
    // console.log('hide1', this.element, event.target)
    this.leave()
    this.removeEvent()
  }

  outsideHide(event) {
    // this.hide(event)
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.leave()
      this.removeEvent()
    }
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      document.removeEventListener("click", this.eventCreated)
      this.eventCreated = false
    }
  }

  disconnect() {
    this.removeEvent()
  }

  add() {
    document.getElementById("invoice_client_id").value = ""
    if (this.companyValue) {
      document.querySelector("#invoice_buyer_name").value = this.inputTarget.value
    } else {
      document.querySelector("#invoice_buyer_first_name").value = this.inputTarget.value
    }
    this.toggle()
  }
}
