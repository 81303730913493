import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["select", "title", "street", "streetAdditional", "city", "postCode", "country", "titleWrapper"]

  static values = {
    path: String,
    deliveryAddresses: Object,
    clientId: Number,
    newRecord: Boolean,
    translations: Object,
  }

  connect() {
    if (this.hasSelectTarget) {
      if (this.clientIdValue) {
        this.loadDeliveryAddresses(this.clientIdValue)
      }
    }
  }

  loadDeliveryAddresses(clientId) {
    Rails.ajax({
      type: "get",
      url: this.pathValue.replace("-1", `${clientId}`),
      dataType: "json",
      success: (data) => {
        if (this.newRecordValue) {
          const newAddress = document.createElement("option")
          newAddress.value = ""
          newAddress.innerText = this.translationsValue["address.new"]
          this.selectTarget.replaceChildren(newAddress)
        }
        const addresses = {}
        data.forEach((item) => {
          addresses[item.id] = item
          if (this.newRecordValue) {
            const option = document.createElement("option")
            option.value = `${item.id}`
            option.innerText = item.name
            this.selectTarget.appendChild(option)
          }
        })
        this.deliveryAddressesValue = addresses
        this.selectTarget.value = this.selectTarget.querySelector(":nth-of-type(2)")?.value ?? ""
        this.selectTarget.dispatchEvent(new Event("change"))
      },
    })
  }

  deliveryAddressSelectChanged(event) {
    const addressId = event.target.value

    if (!(addressId in this.deliveryAddressesValue)) {
      this.titleWrapperTarget.classList.remove("hidden")
      this.titleTarget.value = ""
      this.streetTarget.value = ""
      this.streetAdditionalTarget.value = ""
      this.cityTarget.value = ""
      this.postCodeTarget.value = ""
      this.countryTarget.value = "FR"
      return
    }

    const address = this.deliveryAddressesValue[event.target.value]
    this.titleWrapperTarget.classList.add("hidden")
    this.titleTarget.value = address.name
    this.streetTarget.value = address.street
    this.streetAdditionalTarget.value = address.street_additional
    this.cityTarget.value = address.city
    this.postCodeTarget.value = address.post_code
    this.countryTarget.value = address.country
  }
}
