import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sideNav"]
  static values = { class: String }

  toggleSideNav() {
    for (const cls of this.classValue.split(/\s+/)) {
      this.sideNavTarget.classList.toggle(cls)
    }
  }

  notifyNativeApp({ params: { language } }) {
    window.TurboNativeBridge.postMessage("changeLanguage", { language })
  }
}
