import { Controller } from "@hotwired/stimulus"
import { formatNumber } from "./helpers"

export default class extends Controller {
  static targets = ["totalCount"]

  static values = {
    locale: String,
    accountCurrency: String,
    invoiceValues: Object,
    totalSums: Object,
    totalCount: Number,
  }

  keys = []
  initialSums
  initialCount
  currencyOptions = { format: "1.1", locale: this.localeValue, app_digits: 2 }

  connect() {
    this.keys = Object.keys(this.totalSumsValue).filter(key => key !== "count")
    this.initialSums = this.totalSumsValue
    this.initialCount = this.totalCountValue
  }

  updateTotals(selectedRecords) {
    let partialSums = {}

    if (selectedRecords.length === 0) {
      partialSums = this.initialSums
      this.totalCount = this.initialCount
    } else {
      this.totalCount = selectedRecords.length
      selectedRecords.forEach((recordId) => {
        const record = this.invoiceValuesValue[parseInt(recordId)]
        this.keys.forEach((key) => {
          const currency = record["currency"]
          if (!(key in partialSums)) partialSums[key] = {}
          if (!(currency in partialSums[key])) {
            partialSums[key][currency] = record[key]
          } else {
            partialSums[key][currency] += record[key]
          }

          if (!("total_exchanged_sum" in partialSums[key])) {
            partialSums[key]["total_exchanged_sum"] =
              record[key] * (record["exchange_rate"] / record["exchange_rate_den"])
          } else {
            partialSums[key]["total_exchanged_sum"] +=
              record[key] * (record["exchange_rate"] / record["exchange_rate_den"])
          }
        })
      })
    }

    this.updateSums(partialSums)
    window.FIRMLET_BULK_IDS = selectedRecords //TODO usunac po przepisaniu reszty js
  }

  updateSums(partialSums) {
    this.keys.forEach((key) => {
      let result = ""
      if (document.getElementById(`total_${key}_values`)) {
        Object.keys(partialSums[key]).forEach((currency) => {
          if (currency === "total_exchanged_sum") return
          result += `${formatNumber(partialSums[key][currency], this.currencyOptions)} ${currency} </br>`
        })

        document.getElementById(`total_${key}_values`).innerHTML = result
      }
    })

    if (this.keys.length > 0 && (Object.keys(partialSums[Object.keys(partialSums)[0]]).length > 2 ||
        Object.keys(partialSums[this.keys[0]])[0] !== this.accountCurrencyValue)) {
      this.keys.forEach((key) => {
        if (!document.getElementById(`total_${key}_sum`)) return
        document.getElementById(`total_${key}_sum`).innerHTML =
          `${formatNumber(partialSums[key]["total_exchanged_sum"], this.currencyOptions)} ${this.accountCurrencyValue}`
        document.getElementById(`total_${key}_separator`)?.classList.remove("hidden")
        document.getElementById(`total_${key}_sum`).classList.remove("hidden")
      })
    } else {
      this.keys.forEach((key) => {
        document.getElementById(`total_${key}_separator`)?.classList.add("hidden")
        document.getElementById(`total_${key}_sum`)?.classList.add("hidden")
      })
    }

    this.totalCountTarget.textContent = `${this.totalCount}`
  }
}
