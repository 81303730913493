import { Controller } from "@hotwired/stimulus"
import { showNotification } from "./helpers"

export default class extends Controller {
  static targets = [
    "issueDate",
    "sellDate",
    "deliveryDate",
    "incomeTaxDate",
    "vatTaxDate",
    "clearIncomeTaxDate",
    "clearVatTaxDate"
  ]

  static values = {
    isIncome: Boolean,
    incomeTaxDateChangedMsg: String,
    vatTaxDateChangedMsg: String
  }

  handleAccountingDates ({ skipNotification = false } = {}) {
    const inputs = this._getDateInputs()
    const inputsData = this._computeDataFromDateInputs(inputs)

    this._handleVatTaxDate({ inputsData, skipNotification })
    this._handleIncomeTaxDate({ inputsData, skipNotification })
  }

  clearVatTaxDateChange () {
    this._handleVatTaxDate({ skipNotification: true })
  }

  _handleVatTaxDate ({ inputsData = null, skipNotification = false } = {}) {
    const vatTaxDateInput = this._getVatTaxDateInput()
    if (!vatTaxDateInput) return

    if (this._isVatClearingChecked()) {
      vatTaxDateInput.value = ""
      vatTaxDateInput.readOnly = true

      return
    }

    const _inputsData = inputsData || this._computeDataFromDateInputs(this._getDateInputs())
    let vatTaxDate = null
    if (this.isIncomeValue) {
      vatTaxDate = _inputsData.sellDate
    } else {
      vatTaxDate = this._isImportServiceVat() ? _inputsData.sellDate : _inputsData.deliveryDate
      if (!vatTaxDate) vatTaxDate = _inputsData.issueDate
    }

    if (!skipNotification && (_inputsData.vatTaxDate.getMonth() !== vatTaxDate?.getMonth() || _inputsData.vatTaxDate.getFullYear() !== vatTaxDate?.getFullYear())) {
      showNotification(this.vatTaxDateChangedMsgValue)
    }

    vatTaxDateInput.value = vatTaxDate
      ? this._formatDateToInputValue(vatTaxDate)
      : ""

    vatTaxDateInput.readOnly = false
  }

  clearIncomeTaxDateChange () {
    this._handleIncomeTaxDate({ skipNotification: true })
  }

  _handleIncomeTaxDate ({ inputsData = null, skipNotification = false } = {}) {
    const incomeTaxDateInput = this._getIncomeTaxDateinput()
    if (!incomeTaxDateInput) return

    if (this._isIncomeClearingChecked()) {
      incomeTaxDateInput.value = ""
      incomeTaxDateInput.readOnly = true

      return
    }

    const _inputsData = inputsData || this._computeDataFromDateInputs(this._getDateInputs())
    let incomeTaxDate = null
    if (this.isIncomeValue) {
      const dateInts = []
      for (const dateName of ["sellDate", "issueDate"]) {
        if (_inputsData[dateName]) dateInts.push(_inputsData[dateName].getTime())
      }

      incomeTaxDate = dateInts.length > 0
        ? new Date(Math.min(...dateInts))
        : null
    } else {
      incomeTaxDate = _inputsData.issueDate || null
    }

    if (!skipNotification && (_inputsData.incomeTaxDate.getMonth() !== incomeTaxDate?.getMonth() || _inputsData.incomeTaxDate.getFullYear() !== incomeTaxDate?.getFullYear())) {
      showNotification(this.incomeTaxDateChangedMsgValue)
    }

    incomeTaxDateInput.readOnly = false
    incomeTaxDateInput.value = incomeTaxDate
      ? this._formatDateToInputValue(incomeTaxDate)
      : ""
  }

  _getDateInputs () {
    return {
      issueDate: document.getElementById("invoice_issue_date"),
      sellDate: document.getElementById("invoice_sell_date"),
      deliveryDate: document.getElementById("invoice_delivery_date"),
      incomeTaxDate: this._getIncomeTaxDateinput(),
      vatTaxDate: this._getVatTaxDateInput()
    }
  }

  _getIncomeTaxDateinput () {
    return document.getElementById("invoice_accounting_income_tax_date")
  }

  _getVatTaxDateInput () {
    return document.getElementById("invoice_accounting_vat_tax_date")
  }

  _isVatClearingChecked () {
    return !!document.getElementById("invoice_clear_vat_tax_date")?.checked
  }

  _isIncomeClearingChecked () {
    return !!document.getElementById("invoice_clear_income_tax_date")?.checked
  }

  _computeDataFromDateInputs (inputs) {
    const ret = {}

    for (const dateName of ["issueDate", "sellDate", "deliveryDate", "incomeTaxDate", "vatTaxDate"]) {
      ret[dateName] = inputs[dateName]?.value ? new Date(inputs[dateName].value) : null
    }

    return ret
  }

  _formatDateToInputValue (date) {
    return date.toISOString().split("T")[0]
  }

  _getInvoiceKind () {
    return document.getElementById("invoice_kind")?.value
  }

  _isImportServiceVat () {
    return ["import_service", "import_service_eu"].includes(this._getInvoiceKind())
  }
}
