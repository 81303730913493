import { Controller } from "@hotwired/stimulus"
import { useTransition, useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["container", "outsideClose"]
  static values = {
    outsideOpenId: String,
    outsideOpenTarget: String,
    hiddenByDefault: { type: Boolean, default: true },
    disableBodyScroll: Boolean
  }

  connect() {
    useTransition(this, { element: this.containerTarget }) // stimulus-use
    if (this.hiddenByDefaultValue) {
      this.containerTarget.classList.add("hidden")
    } else {
      this.containerTarget.classList.remove("hidden")
    }

    if (this.outsideOpenTargetValue) {
      document.querySelectorAll(this.outsideOpenTargetValue).forEach(element => {
        element.onclick = this.open.bind(this)
      })
    }

    if (this.outsideOpenIdValue) {
      document.getElementById(this.outsideOpenIdValue).onclick = this.open.bind(this)
    }

    if (this.hasOutsideCloseTarget) {
      useClickOutside(this, {
        element: this.outsideCloseTarget
      })
    }
  }

  open() {
    this.containerTarget.classList.remove("hidden")
    if (this.disableBodyScrollValue) {
      document.body.classList.add("overflow-hidden")
    }
  }

  hide(event) {
    event.preventDefault()
    this.containerTarget.classList.add("hidden")
    if (this.disableBodyScrollValue) {
      document.body.classList.remove("overflow-hidden")
    }
  }

  clickOutside(event) {
    this.hide(event)
  }
}
