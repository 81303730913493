import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.querySelector("body").addEventListener("dragover", this.handleDragOver.bind(this))
  }

  disconnect() {
    document.querySelector("body").removeEventListener("dragover", this.handleDragOver.bind(this))
  }

  handleDragOver(event) {
    if (typeof showOcrModal === "function") {
      showOcrModal(event)
    } else {
      console.log("Metoda showOcrModal nie jest dostepna")
    }
  }
}
