import { Application } from "@hotwired/stimulus"
import * as Sentry from "@sentry/browser"

const application = Application.start()

if (process.env.RAILS_ENV === "production") {
  Sentry.init({
    dsn: "https://e2e6458daaf346ab8de1e3963f889b63@o320194.ingest.sentry.io/4504876304760832",
    maxBreadcrumbs: 50,
    environment: process.env.RAILS_ENV,
  })
}

// Configure Stimulus development experience
application.warnings = true
application.debug = false
window.Stimulus = application

export { application }
