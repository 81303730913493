import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["menu", "list", "status", "statusButton", "sentIcon", "progressBar"]

  eventCreated = false

  static values = {
    currentColor: String,
    currentColorBar: String,
    currentStatus: String,
    mode: { type: String, default: "single" }
  }

  connect() {
    console.log("dropdown-status connect")
    useTransition(this, { element: this.menuTarget })

    this.element.addEventListener("update-status", (event) => {
      const params = event.detail
      this.updateStatus(params)
    })
  }

  toggle() {
    this.toggleTransition()
    // https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      document.addEventListener("click", fn)
    } else {
      this.removeEvent()
    }
  }

  hide() {
    this.leave()
    this.removeEvent()
  }

  outsideHide(event) {
    // this.hide(event)
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains("hidden")) {
      this.hide()
    }
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      document.removeEventListener("click", this.eventCreated)
      this.eventCreated = false
    }
  }

  disconnect() {
    this.removeEvent()
  }

  changeStatus(event) {
    event.preventDefault()
    const params = event.params

    this.currentStatusValue = event.target.textContent

    if (this.modeValue === "multiple") {
      this.dispatchUpdateStatus(params)
    } else {
      this.updateStatus(params)
    }
    this.statusTarget.dispatchEvent(new Event("change"))

    if (params.status === "paid") {
      window.TurboNativeBridge.postMessage("sendEvent", { event: "paid_invoice" })
    }
    this.hide()
  }

  updateStatus(params) {
    if (params.status === "sent") {
      if (this.hasSentIconTarget) {
        this.sentIconTarget.classList.remove("hidden")
      }
    } else {
      if (this.currentStatusValue !== "") {
        this.statusTarget.textContent = this.currentStatusValue
      }

      this.statusButtonTarget.classList.remove(...this.currentColorValue.split(" "))
      this.statusButtonTarget.classList.add(...params.color.split(" "))

      if (this.hasProgressBarTarget) {
        this.progressBarTarget.classList.add("hidden")
      }

      const statusBar = document.querySelector("[data-target~='statusBar']")

      if (statusBar) {
        document.querySelector("[data-target~='statusBar']").classList.remove(...this.currentColorBarValue.split(" "))
        document.querySelector("[data-target~='statusBar']").classList.add(...params.colorBar.split(" "))
      }
    }

    this.currentColorValue = params.color
    this.currentColorBarValue = params.colorBar
  }

  dispatchUpdateStatus(params) {
    const event = new CustomEvent("update-status", {
      detail: params
    })

    const allStatusElements = document.querySelectorAll("[data-controller='dropdown-status']")
    allStatusElements.forEach((element) => {
      element.setAttribute("data-dropdown-status-current-status-value", this.currentStatusValue)
      element.dispatchEvent(event)
    })
  }

  setParamsForModal(event) {
    document.getElementById("partial_payment_invoice_id").value = event.params.invoiceId
    document.getElementById("partial_payment_price").value = event.params.price
  }
}
