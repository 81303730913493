import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["controlled"]

  controlledElement() {
    if (this.hasControlledTarget) {
      return this.controlledTarget
    } else {
      return this.element
    }
  }

  hide() {
    this.controlledElement().classList.add("hidden")
  }

  show() {
    this.controlledElement().classList.remove("hidden")
  }

  toggle(event) {
    if (event.target.checked) {
      this.show()
    } else {
      this.hide()
    }
  }
}
