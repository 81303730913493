import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    console.log('form connect')
    if (this.hasFormTarget) {
      this.formTarget.addEventListener("turbo:submit-end", async ({ detail }) => {
        const nonGetFetch = detail.formSubmission.fetchRequest.method !== 0
        const responseHTML = await detail.fetchResponse?.responseHTML
        if (detail.success && nonGetFetch && responseHTML) {
          setTimeout(() => {
            Turbo.clearCache()
          }, 1000)
        }
      })
    }
  }

  selectToTextField(event) {
    event.preventDefault()
    const expectedValue = event.params.selectValue
    const element = event.currentTarget
    const selectedValue = element.selectedOptions[0].value
    if (expectedValue === selectedValue) {
      var input = document.createElement('input')
      input.type = 'text'
      input.setAttribute("id", element.getAttribute('id'))
      input.setAttribute("name", element.getAttribute('name'))
      input.setAttribute("class", element.getAttribute('class'))
      this.copyDataset(element, input)
      if (element.hasAttribute('placeholder')) {
        input.setAttribute("name", element.getAttribute('placeholder'))
      }

      element.after(input)
      element.remove()
      input.focus()
    }
  }

  showIffChecked(event) {
    const element = event.currentTarget
    const selector = event.params.showSelector
    this.element.querySelectorAll(selector).forEach(
      element.checked
        ? elem => elem.classList.remove("hidden")
        : elem => elem.classList.add("hidden")
    )
  }

  hideIfChecked(event) {
    const element = event.currentTarget
    const selector = event.params.showSelector
    this.element.querySelectorAll(selector).forEach(
      element.checked
        ? elem => elem.classList.add("hidden")
        : elem => elem.classList.remove("hidden")
    )
  }

  copyDataset(elemFrom, elemTo) {
    return Array.from(elemFrom.attributes).forEach(attribute => {
      if (/^data-/.test(attribute.name) && !/data-action/.test(attribute.name)) {
        let attributeName = attribute.nodeName
        switch (attribute.nodeName) {
          case 'data-onchange':
            attributeName = 'data-action'
            break
          case 'data-inputmode':
            attributeName = 'inputmode'
            break
        }
        elemTo.setAttribute(
          attributeName,
          attribute.nodeValue,
        )
      }
    })
  }

  validateAttachment(event) {
    document.getElementById("attachment_error").classList.add("hidden")
    const files = document.querySelectorAll("#file_list a").length
    if (files === 0) {
      event.preventDefault()
      document.getElementById("attachment_error").classList.remove("hidden")
      return false
    }
  }

  forceSetValueOnSelect(event) {
    if (event.detail.val !== '' && event.detail.val !== null) {
      event.detail.select.value = event.detail.val
      if (event.detail.select.value !== event.detail.val) {
        event.detail.select.insertAdjacentHTML('beforeend', `<option value="${event.detail.val}">${event.detail.val}</option>`)
        event.detail.select.value = event.detail.val
      }
    } else {
      event.detail.select.value = ""
    }
  }

  confirm(event) {
    if (!window.confirm(event.params.message)) {
      event.preventDefault()
    }
  }

  /** @param {Event & {params: {message: string, field: string, initialValue: string}}} event */
  reloadOnChange(event) {
    if (confirm(event.params.message)) {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)
      searchParams.set(event.params.field, event.target.value)
      url.search = searchParams.toString()
      Turbo.cache.clear()
      Turbo.visit(url.toString())
    } else {
      event.target.value = event.params.initialValue
    }
  }

  toggleShowPaidDateCheckbox(event) {
    event.preventDefault()
    const value = event.target.value
    const showPaidDate = event.target.dataset.account === 'true'
    const paidDate = document.getElementById('show_paid_date')

    if (value) {
      paidDate.classList.remove('hidden')
    } else if (!showPaidDate) {
      paidDate.classList.add('hidden')
    }
  }
}
