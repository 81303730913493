import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { escapeHtml, forceSetValueOnSelect, isEmpty, valueChangedFromDefault, isoDateToString, showNotification } from "./helpers"

export default class extends Controller {
  static targets = ["body", "send", "buyerAdditionalGroup", "buyerAdditionalOpen", "buyerAdditionalClose",
    "taxNo", "invoiceAdditionalGroup", "invoiceAdditionalClose", "invoiceAdditionalOpen", "currency", "allCurrencies",
    "taxField", "exemptTaxField", "discount", "sellDate", "issueDate", "deliveryDate", "warehousesSelect", "departmentSelect",
    "buttonRecipient", "recipient",
    "invoiceTemplateSelect", "descriptionField", "attachmentsList", "showExemptTaxField", "exchangeKind",
    "exchangeCurrency", "exchangeKindWrapper", "ownExchangeDetails", "cancelModal", "additionalInfo",
    "descriptionLongField", "prepaymentExactAmountField", "positionsContainer", "paymentReferenceNumber",
    "massPaymentPattern", "massPaymentCode", "sellerBankAccount", "showIban", "buyerCountry",
    "deliveryAddressWrapper", "condNote", "sellerCountry", "usePrimeCee", "primeCeeWrapper", "form",
    "documentInfoWrapper", "priceOfferSummary", "priceOfferNoCatalogue", "paymentModal",
    "naTaxKind", "naTaxKindRadios", "naTaxKindInnerWrapper", "viesButton", "bdoNo", "priceList", "roundPrice", "sellerBankAccountInfo", "sellerBank", "sellerBankSwift"
  ]

  defaultSellDate
  defaultCondNote
  defaultTax
  defaultPriceList

  static values = {
    locale: String,
    newRecord: Boolean,
    correction: Boolean,
    kind: String,
    income: Boolean,
    translations: Object,
    departments: Object,
    accountTemplateId: Number,
    accountInvoiceDescription: Boolean,
    myWarehousesForSelect: Array,
    myWarehousesForSelectByDepartment: Object,
    warehouseId: Number,
    defaultLang: String,
    exchangeRatesAvailableForBanks: Object,
    accountCurrency: String,
    accountInvoiceDescriptionLong: String,
    kindDescription: String,
    taxVisible: Boolean,
    tax2Visible: Boolean,
    netVisible: Boolean,
    grossVisible: Boolean,
    discountKind: String,
    showAdditionalInfo: Boolean,
    spareColClass: String,
    defaultNumber: String,
    defaultPattern: String,
    defaultKey: String,
    accountUseMassPayment: Boolean,
    accountCountry: String,
    euCountries: Array,
    defaultCondNotes: Object,
    handleCondNote: Boolean,
    defaultExpiryDate: String,
    naTaxKinds: Array,
    exemptTaxKinds: Array,
    viesUrl: String,
    ksefEnabled: Boolean,
    priceSetup: Object,
    usePriceList: Boolean,
    defaultCurrency: String,
    fromInvoiceSellerBankAccount: String,
    fromInvoiceSellerBank: String,
    departmentBankAccount: String,
    fromInvoiceSellerBankSwift: String,
  }

  static outlets = ["invoice-position", "invoice-lang", "currency", "moss"]

  connect() {
    this.defaultTaxOpts = Array.from(this.hasTaxFieldTarget && this.taxFieldTarget.options ? this.taxFieldTarget.options : []).map(option => option.cloneNode(true))

    if (this.hasSendTarget && this.sendTarget.classList.contains("errors")) {
      this.showForm()
      this.sendTarget.classList.remove("errors")
    }

    if (this.hasSellDateTarget) {
      this.defaultSellDate = this.sellDateTarget.value
    }

    if (this.hasPriceListTarget) {
      this.defaultPriceList = this.priceListTarget.value
    }

    if (this.hasMassPaymentCodeTarget) {
      document.getElementById("invoice_buyer_mass_payment_code")?.dispatchEvent(new Event("change"))
    }

    this.updateWarehouseChoices()

    if (this.hasFormTarget) {
      this.formTarget.addEventListener("turbo:submit-end", () => {
        this.handleSubmitEnd()
      })
    }

    this.defaultCondNote = this.newRecordValue

    if (this.localeValue === "fr" && this.newRecordValue) {
      this.changeSellDate()
    }

    if (this.hasViesButtonTarget) {
      this.updateViesVisibility()
    }

    this.setBankAccountNumberFromInvoice()
  }

  async handleSubmitEnd() {
    await this.waitForTurboLoad()
    this.handleNewPageLoaded()
  }

  waitForTurboLoad() {
    return new Promise((resolve) => {
      const handleLoad = () => {
        resolve()
        document.removeEventListener("turbo:load", handleLoad)
      }
      document.addEventListener("turbo:load", handleLoad)
    })
  }

  handleNewPageLoaded() {
    if (this.newRecordValue && navigator.userAgent.includes("Turbo Native")) {
      const invElem = document.querySelector("[data-controller='invoice']")
      if (invElem) {
        window.TurboNativeBridge.postMessage("sendEvent", {
          event: "create_invoice",
          incomes: invElem.dataset.incomes,
          expenses: invElem.dataset.expenses
        })
      }
    }
  }

  updateWarehouseChoices() {
    if (this.hasWarehousesSelectWithOptions()) {
      const departmentId = this.hasDepartmentSelectTarget ? this.departmentSelectTarget.value : ""
      const departmentWarehouses = this.warehousesForDepartment(departmentId)
      const newWarehousesSelectOptions = this.warehousesSelectOptions(departmentWarehouses)

      this.warehousesSelectTarget.replaceChildren(...newWarehousesSelectOptions)
      if (departmentWarehouses.find(w => w[1] === this.warehouseIdValue) === undefined) {
        this.warehousesSelectTarget.value = this.warehouseIdValue = this.warehousesSelectTarget.firstChild.value
        showNotification(this.translationsValue["warehouse_has_changed"], true, ["{warehouse_name}"], [this.warehousesSelectTarget.firstChild.innerText])
      } else {
        this.warehousesSelectTarget.value = this.warehouseIdValue
      }
    }
  }

  warehousesSelectChanged() {
    this.warehouseIdValue = this.warehousesSelectTarget.value
  }

  warehousesSelectOptions(warehousesForSelect) {
    return warehousesForSelect.map(([name, value]) => {
      const option = document.createElement("option")
      option.value = value
      option.innerText = name
      return option
    })
  }

  warehousesForDepartment(departmentId) {
    if (departmentId === "" || (this.myWarehousesForSelectByDepartmentValue[departmentId] ?? []).length < 1) {
      return this.myWarehousesForSelectValue
    } else {
      return this.myWarehousesForSelectByDepartmentValue[departmentId]
    }
  }

  hasWarehousesSelectWithOptions() {
    return this.hasWarehousesSelectTarget && this.warehousesSelectTarget.firstChild != null
  }

  showForm() {
    if (this.sendTarget.classList.contains('hidden')) {
      this.bodyTarget.classList.add('hidden')
      this.sendTarget.classList.remove('hidden')
      this.sendTarget.scrollIntoView()
    }
  }

  emailChange() {
    document.getElementById("email_change_div").classList.remove("hidden")
    document.querySelectorAll('input[name="invoice[email_change]"]').forEach(input => {
      input.disabled = false
    })
  }

  changeReplyTo() {
    const selectBox = document.getElementById("invoice_email_from")
    const value = selectBox.options[selectBox.selectedIndex].text
    document.getElementById("invoice_reply_to").value = value
  }

  hide() {
    if (this.bodyTarget.classList.contains('hidden')) {
      this.sendTarget.classList.add('hidden')
      this.bodyTarget.classList.remove('hidden')
      this.bodyTarget.scrollIntoView()
    }
  }

  switchBuyerType(event) {
    if (event.target.value === 'true') {
      document.querySelectorAll("[data-buyer-person]").forEach((element) => element.classList.add('hidden'))
      document.querySelectorAll("[data-buyer-company]").forEach((element) => element.classList.remove('hidden'))
      document.querySelector("#invoice_buyer_name").closest("[data-buyer-company]").after(this.taxNoTarget)
      if (this.localeValue === "fr") {
        this.taxNoTarget.classList.remove("hidden")
      }
    } else {
      document.querySelectorAll("[data-buyer-company]").forEach((element) => element.classList.add('hidden'))
      document.querySelectorAll("[data-buyer-person]").forEach((element) => element.classList.remove('hidden'))
      this.buyerAdditionalGroupTarget.append(this.taxNoTarget)
      if (this.localeValue === "fr") {
        this.taxNoTarget.classList.add("hidden")
      }
    }
  }

  toggleBuyerAdditional() {
    [this.buyerAdditionalGroupTarget, this.buyerAdditionalCloseTarget, this.buyerAdditionalOpenTarget].forEach((element) => element.classList.toggle("hidden"))
  }

  toggleInvoiceAdditional() {
    [this.invoiceAdditionalGroupTarget, this.invoiceAdditionalCloseTarget, this.invoiceAdditionalOpenTarget].forEach((element) => element.classList.toggle("hidden"))
  }

  companySetName() {
    document.getElementById('invoice_buyer_name').value = document.getElementById('invoice_buyer_first_name').value + ' ' +
      document.getElementById('invoice_buyer_last_name').value
  }

  recipientCompanySetName() {
    document.getElementById('invoice_recipient_name').value = document.getElementById('invoice_recipient_first_name').value + ' ' +
      document.getElementById('invoice_recipient_last_name').value
  }

  showDepartmentForm() {
    this.element.querySelector("#invoice_checkbox_show_seller").value = "1"
    this.element.querySelectorAll("[data-no-seller]").forEach((element) => element.classList.add("hidden"))
    this.element.querySelectorAll("[data-seller]").forEach((element) => element.classList.remove("hidden"))
  }

  changeDocumentNumber(event) {
    const keepIfSamePattern = event.params.keepPattern || false
    const testMode = false // test_mode = $("#" + prefix + "_test").is(":checked")
    const number = document.getElementById('invoice_number').value
    const departmentId = document.getElementById('invoice_department_id')?.value
    if (this.defaultNumberValue !== '' && this.defaultKeyValue === (this.issueDateTarget.value + "-" + departmentId + "-" + this.kindValue + "-" + testMode)) {
      this.updateInvoiceNumber(this.defaultPatternValue, this.defaultNumberValue)
    } else {
      const params = {
        issue_date: this.issueDateTarget.value,
        kind: this.kindValue,
        document_id: event.params.id || '',
        document_type: 'invoice',
        test_mode: testMode
      }

      if (departmentId) {
        params.department_id = departmentId
      }

      Rails.ajax({
        type: 'get',
        url: '/invoices/get_new_number',
        dataType: 'json',
        data: new URLSearchParams(params),
        success: (data) => {
          if (data['nr'] !== document.getElementById("number_check").value && !(data['pattern'] === document.getElementById("invoice_pattern").value && keepIfSamePattern)) {
            if (number !== '' && number !== document.getElementById("number_check").value) {
              showNotification(this.translationsValue["pattern_message_not_change"])
            } else {
              this.updateInvoiceNumber(data['pattern'], data['nr'])
              showNotification(this.translationsValue["pattern_message_change"], true, ["{number_from}", "{number_to}"], [number, data["nr"]])
            }
          }
        }
      })
    }
  }

  updateInvoiceNumber(pattern, number) {
    document.getElementById("invoice_pattern").value = pattern
    document.getElementById("number_check").value = number
    document.getElementById("invoice_number").value = number
  }

  handlePaymentToOtherDate(event) {
    const dateField = this.element.querySelector('[name="invoice[payment_to]"]')
    if (event.target.value === "other_date") {
      dateField.classList.remove("hidden")
    } else {
      dateField.classList.add("hidden")
    }
  }

  changePartialStatus(event) {
    if (event.target.value === "partial") {
      document.querySelector("[data-partial-paid]").classList.remove("hidden")
    } else {
      document.querySelector("[data-partial-paid]").classList.add("hidden")
    }
  }

  isMossOn() {
    return this.hasMossOutlet && this.mossOutlet.useMossTarget.checked
  }

  updateNaKindVisibility() {
    // TODO: if correction
    if (!this.isMossOn() && this.isTaxNaAnywhere()) {
      this.naTaxKindRadiosTarget.classList.remove("hidden")
      const notSpecifiedRadio = this.naTaxKindRadiosTarget.querySelector('input[value="not_specified"]') // nie istnieje na ksefie
      if (this.naTaxKindTarget.value === "" && notSpecifiedRadio !== null) {
        notSpecifiedRadio.checked = true
        notSpecifiedRadio.dispatchEvent(new Event("change"))
      }
    } else {
      this.naTaxKindRadiosTarget.classList.add("hidden")
      this.clearNaKind()
    }
  }

  clearNaKind() {
    this.naTaxKindTarget.value = ""
    this.naTaxKindRadiosTarget.querySelectorAll('input[type="checkbox"], input[type="radio"]').forEach(elem => { elem.checked = false })
    this.naTaxKindInnerWrapperTarget.classList.add("hidden")
  }

  updateNaKind(event) {
    if (!this.isMossOn()) {
      const val = event.target.value
      if (val === "export_service_eu") {
        this.naTaxKindTarget.value = event.target.checked ? val : this.naTaxKindRadiosTarget.querySelector("input:checked").value
      } else {
        if (val === "export_service") {
          this.naTaxKindInnerWrapperTarget.classList.remove("hidden")
        } else {
          this.naTaxKindInnerWrapperTarget.classList.add("hidden")
          this.naTaxKindInnerWrapperTarget.querySelector("input").checked = false
        }
        this.naTaxKindTarget.value = val
      }
    }
  }

  /**
   * @param {string[]} options
   */
  isTaxMatchingAnywhere(options) {
    return this.taxFieldTargets.find((element) => options.includes(element.value)) !== undefined
  }

  isTaxNaAnywhere() {
    return this.isTaxMatchingAnywhere(this.naTaxKindsValue)
  }

  isTaxExemptAnywhere() {
    return this.isTaxMatchingAnywhere(this.exemptTaxKindsValue)
  }

  updateExemptTaxVisibility() {
    if (!this.hasExemptTaxFieldTarget) return

    const shouldShowExempt = (this.hasShowExemptTaxFieldTarget && this.showExemptTaxFieldTarget.checked) || this.isTaxExemptAnywhere()
    if (shouldShowExempt) {
      this.exemptTaxFieldTarget.classList.remove("hidden")
    } else {
      this.exemptTaxFieldTarget.classList.add("hidden")
    }
  }

  checkPositions(event) {
    let confBox = false
    document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_name']").forEach((element, index) => {
      if (document.getElementById("invoice_positions_attributes_" + index + "_save_to_database") &&
        !document.getElementById("invoice_positions_attributes_" + index + "_save_to_database").checked &&
        !document.getElementById("invoice_positions_attributes_" + index + "__destroy").checked) {
        if (document.getElementById("invoice_positions_attributes_" + index + "_product_id").value.length === 0 &&
          document.getElementById("invoice_positions_attributes_" + index + "_name").value.length > 0) {
          confBox = true
        }
      }
    })

    if (event.params.productStrategy !== "create_if_new") {
      confBox = false
    }

    if (confBox && !event.params.forceNotSavingProduct) {
      const confirmed = confirm(event.params.productAddConfirm)
      if (!confirmed) {
        event.preventDefault()
        return false
      }
    }
  }

  changeDiscount() {
    this.discountTarget.checked = false
    const discountPercent = Array.from(document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_discount_percent']")).filter((elem) => {
      return elem.value.length > 0
    })

    const discount = Array.from(document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_discount']")).filter((elem) => {
      return (elem.value.length > 0 && parseFloat(elem.value) > 0)
    })

    if (discountPercent.length > 0 || discount.length > 0) {
      this.discountTarget.checked = true
    }
  }

  changeAdditionalInfo() {
    this.additionalInfoTarget.checked = false
    const additionalInfo = Array.from(document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_additional_info']")).filter((elem) => {
      return (elem.value.length > 0)
    })

    if (additionalInfo.length > 0) {
      this.additionalInfoTarget.checked = true
    }
  }

  setGlobalDiscount(event) {
    document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_discount_percent']").forEach((elem) => {
      elem.value = event.target.value
    })
  }

  changeDiscountKind(event) {
    this.discountKindValue = event.target.value
    if (event.target.value === 'amount') {
      document.querySelectorAll("[data-discount-amount]").forEach((element) => element.classList.remove('hidden'))
      document.querySelectorAll("[data-discount-percent]").forEach((element) => element.classList.add('hidden'))
    } else if (event.target.value !== 'amount') {
      document.querySelectorAll("[data-discount-amount]").forEach((element) => element.classList.add('hidden'))
      document.querySelectorAll("[data-discount-percent]").forEach((element) => element.classList.remove('hidden'))
    }
  }

  changeSellDate() {
    if (this.localeValue === "pl") {
      if (this.defaultSellDate === this.sellDateTarget.value && this.issueDateTarget.value !== this.sellDateTarget.value) {
        this.defaultSellDate = this.sellDateTarget.value = this.issueDateTarget.value
        if (!this.newRecordValue) {
          showNotification(this.translationsValue["date_auto_change_message"], true, ["{sell_date}"], [isoDateToString(new Date(this.defaultSellDate))])
        }
      }
    }
    if (this.localeValue === "fr" && this.defaultExpiryDateValue !== "") {
      if (this.hasSellDateTarget) {
        const prevDate = new Date(this.sellDateTarget.value)
        if (this.defaultSellDate === this.sellDateTarget.value && document.getElementById("invoice_sell_date_kind")?.value !== "") {
          const issueDate = new Date(this.issueDateTarget.value)
          switch (this.defaultExpiryDateValue) {
            case "one_month":
              issueDate.setMonth(issueDate.getMonth() + 1)
              break
            case "two_months":
              issueDate.setMonth(issueDate.getMonth() + 2)
              break
            case "three_months":
              issueDate.setMonth(issueDate.getMonth() + 3)
              break
            case "fifteen_days":
              issueDate.setDate(issueDate.getDate() + 15)
              break
            case "seven_days":
              issueDate.setDate(issueDate.getDate() + 7)
              break
          }
          this.defaultSellDate = this.sellDateTarget.value = isoDateToString(issueDate)
        } else {
          this.defaultSellDate = this.sellDateTarget.value = this.issueDateTarget.value
        }
        if (!this.newRecordValue && isoDateToString(prevDate) !== this.sellDateTarget.value) {
          showNotification(this.translationsValue["date_auto_change_message"], true, ["{sell_date}"], [this.defaultSellDate])
        }
      }
    }
  }

  // tu się dzieje analog funkcji set_dept na desktopie
  setFromDepartment() {
    const deptId = this.departmentSelectTarget.value
    const deptAttributes = this.departmentsValue[deptId] ?? {}
    // pominięte: force set seller country from department (nie edytujemy sellera)
    // hurtowe wypełnienie wszystkich pasujących pól w sellerze (na mobile nie ma ich dużo)
    for (const key in deptAttributes) {
      const field = this.element.querySelector(`#invoice_seller_${key}`)
      if (field !== null) {
        field.value = deptAttributes[key]
      }
    }

    if (this.hasBdoNoTarget) {
      if (isEmpty(deptAttributes["bdo_no"])) {
        this.bdoNoTarget.classList.add("hidden")
      } else {
        this.bdoNoTarget.classList.remove("hidden")
      }
    }
    // wypełnienie wybranych pól na invoicie polami z działu
    // niektóre nie występują na mobile ale lepiej nie zgubić
    const nonSellerOverrideFields = [
      "bank_iban"
      // "seller_trade_register_cz", // if locale?(:cz)
      // pominięte: use_invoice_issuer? invoice_issuer=correspondence_address (tu byłby nieco bardziej zaawansowany przypadek bo boolean)
    ]
    for (const key of nonSellerOverrideFields) {
      if (key in deptAttributes) {
        const field = this.element.querySelector(`#invoice_${key}`)
        if (field !== null) {
          field.value = deptAttributes[key]
        }
      }
    }

    if (this.hasDescriptionLongFieldTarget && "invoice_description_long" in deptAttributes) {
      this.descriptionLongFieldTarget.value = deptAttributes["invoice_description_long"] ?? this.accountInvoiceDescriptionLongValue
    }

    // pominięte segmenty (bo robiłyby się regułą na pola sellera):
    // - locale uk bank account name i sort code
    // - bank_swift

    // zmiana uwag zależnie od działu
    if (this.hasDescriptionFieldTarget) {
      if (!isEmpty(deptAttributes["invoice_description"]) || (isEmpty(this.accountInvoiceDescription) && !valueChangedFromDefault(this.descriptionFieldTarget))) {
        this.descriptionFieldTarget.value = deptAttributes["invoice_description"]
        if (!isEmpty(this.kindDescriptionValue)) {
          this.descriptionFieldTarget.value += (isEmpty(deptAttributes["invoice_description"]) ? "" : "\n") + this.kindDescriptionValue
        }
        this.descriptionFieldTarget.defaultValue = this.descriptionFieldTarget.value
      }
    }

    // pominięte: wykonanie change_document_number (ma się dobrze tam gdzie jest)
    // przepisanie waluty z konta działu
    const bankAccountCurrency = deptAttributes["bank_account_currency"]
    if (!isEmpty(bankAccountCurrency) && this.currencyOutlet.currencyTarget.value !== bankAccountCurrency) {
      forceSetValueOnSelect(this.currencyOutlet.currencyTarget, bankAccountCurrency)
      this.currencyOutlet.currencyTarget.dispatchEvent(new CustomEvent("change"))
      showNotification(this.translationsValue["currency_has_changed"], true, ["{currency}"], [escapeHtml(bankAccountCurrency)])
    }
    // zmiana domyślnego podatku w pustych pozycjach
    if (this.taxVisibleValue) { // TODO: wyciągnąć to w jedno miejsce w dokumencie
      const defaultTax = deptAttributes["default_tax"]
      for (const position of this.invoicePositionOutlets) {
        if (!position.hasTaxFieldTarget) continue
        const taxField = position.taxFieldTarget
        const needsNewOption = !Array.from(taxField.options).some(e => e.value === defaultTax)
        if (needsNewOption) {
          const newOption = document.createElement("option")
          newOption.value = defaultTax
          newOption.innerText = defaultTax
          taxField.append(newOption)
        }
        if (position.isEmpty()) {
          taxField.value = defaultTax
        }
      }
    }
    // pominięte: locale fr forsowana zmiana podatku w pozycjach i reverse charge
    // zmiana szablonu zależnie od działu
    const invoiceTemplateId = (deptAttributes["invoice_template_id"] ?? this.accountTemplateIdValue).toString()
    if (this.hasInvoiceTemplateSelectTarget && this.invoiceTemplateSelectTarget.value !== invoiceTemplateId) {
      this.invoiceTemplateSelectTarget.value = invoiceTemplateId
      showNotification(this.translationsValue["template_has_changed"], true, ["{template_name}"], [this.invoiceTemplateSelectTarget.selectedOptions[0].innerText])
    }

    //zmiana jezyka zaleznie od dzialu
    const invoiceLang = deptAttributes['invoice_lang'] || this.defaultLangValue
    const lang1 = invoiceLang.split('/')[0]
    const lang2 = invoiceLang.split('/')[1] || ''
    let changedLangs = false

    if (this.invoiceLangOutlet.lang1SelectTarget.value !== lang1) {
      this.invoiceLangOutlet.lang1SelectTarget.value = lang1
      changedLangs = true
    }

    if (this.invoiceLangOutlet.lang2SelectTarget.value !== lang2) {
      if (lang2 === '') {
        this.invoiceLangOutlet.removeSecondLang()
      } else {
        if (this.invoiceLangOutlet.lang2SelectTarget.value === '') { this.invoiceLangOutlet.addLangButtonTarget.click() }
        this.invoiceLangOutlet.lang2SelectTarget.value = lang2
        changedLangs = true
      }
    }

    if (changedLangs) {
      let langs = this.invoiceLangOutlet.lang1SelectTarget.selectedOptions[0].innerText
      if (this.invoiceLangOutlet.lang2SelectTarget.selectedOptions.length > 0) {
        langs += `/${this.invoiceLangOutlet.lang2SelectTarget.selectedOptions[0].innerText}`
      }
      showNotification(this.translationsValue["language_changed"], true, ["{language}"], [langs])
    }
    // pominięte: lump_sum_tax
    // pominięte: cofnięcie zmiany działu - numer konta (nie mamy pól seller_bank_account seller_bank_iban)
  }

  changeDeliveryDate(event) {
    if (this.hasDeliveryDateTarget && event.currentTarget.value !== this.deliveryDateTarget.value) {
      const deliveryDate = this.deliveryDateTarget.value
      this.deliveryDateTarget.value = event.currentTarget.value

      if (!(event.params.issueDateWas === event.params.deliveryDateWas && event.params.deliveryDateWas === deliveryDate)) {
        showNotification(this.translationsValue["delivery_date_changed"], true, ["{delivery_date}"], [event.currentTarget.value])
      }
    }
  }

  addRecipient() {
    this.buttonRecipientTarget.classList.toggle("hidden")
    this.recipientTarget.classList.toggle("hidden")
  }

  toggleAttachment() {
    this.attachmentsListTarget.classList.toggle("hidden")
  }

  downloadPDF(event) {
    if (navigator.userAgent.includes("Turbo Native")) {
      window.TurboNativeBridge.postMessage("downloadPDF", { url: event.target.href, filename: event.params.pdfFilename })
    } else {
      window.open(event.target.href, "_blank")
    }
  }

  printPDF(event) {
    if (navigator.userAgent.includes("Turbo Native")) {
      window.TurboNativeBridge.postMessage("printPDF", { url: event.params.pdfUrl, filename: event.params.pdfFilename })
    } else {
      window.open(event.params.pdfUrl, "_blank")
    }
  }

  handleExchangeCurrency() {
    const hasExchangeCurrency = this.exchangeCurrencyTarget.value !== ""
    if (hasExchangeCurrency) {
      this.exchangeKindWrapperTarget.classList.remove("hidden")
      this.notifyIfCurrencyNotInBank()
    } else {
      this.exchangeKindWrapperTarget.classList.add("hidden")
    }
    if (hasExchangeCurrency && this.exchangeKindTarget.value === "own") {
      this.ownExchangeDetailsTargets.forEach((element) => element.classList.remove("hidden"))
    } else {
      this.ownExchangeDetailsTargets.forEach((element) => element.classList.add("hidden"))
    }
  }

  notifyIfCurrencyNotInBank() {
    const currency = this.currencyTarget.value
    /** @type {HTMLSelectElement} */
    const kindSelect = this.exchangeKindTarget
    const kind = kindSelect.value.toUpperCase()
    const exchangeCurrency = this.exchangeCurrencyTarget.value
    if (kind !== "own".toUpperCase() && exchangeCurrency !== "" && currency !== this.accountCurrencyValue) {
      const availableCurrencies = this.exchangeRatesAvailableForBanksValue[kind]
      if (availableCurrencies != null && !availableCurrencies.includes(currency.toUpperCase())) {
        showNotification(this.translationsValue["currency_not_in_bank"], true, ["%{bank}"], [kindSelect.selectedOptions[0].innerText])
      }
    }
  }

  /** @param {Event & {target: HTMLSelectElement, params: {exactAmountValue: string}}} event */
  handlePrepaymentRequiredExactAmount(event) {
    if (event.target.value === event.params.exactAmountValue) {
      this.prepaymentExactAmountFieldTarget.classList.remove("hidden")
    } else {
      this.prepaymentExactAmountFieldTarget.classList.add("hidden")
    }
  }

  showCancelModal() {
    this.cancelModalTarget.classList.remove("hidden")
  }

  hideCancelModal() {
    this.cancelModalTarget.classList.add("hidden")
  }

  changePaymentReferenceNumber(event) {
    if (this.hasPaymentReferenceNumberTarget) {
      this.paymentReferenceNumberTarget.placeholder = event.currentTarget.value.replace(/[^0-9]/g, '')
    }
  }

  showWarning(event) {
    if (event.params.message) {
      showNotification(event.params.message)
    }
  }

  setMassPaymentBankAccount(event) {
    const code = event.currentTarget.value
    if (code !== '') {
      const bankAccount = code.indexOf(this.massPaymentPatternTarget.value) > -1 ? code : (this.massPaymentPatternTarget.value + code)
      this.sellerBankAccountTarget.value = bankAccount
    } else if (this.hasDepartmentSelectTarget) {
      const escapedAccount = this.departmentsValue[this.departmentSelectTarget.value]['bank_account'] ?? ''
      if (this.sellerBankAccountTarget.value !== escapedAccount) {
        if (confirm(this.translationsValue["change_bank_account_to_default"])) {
          this.sellerBankAccountTarget.value = escapedAccount
        }
      }
    }
  }

  checkIban() {
    if (this.hasShowIbanTarget) {
      if ((this.accountCountryValue === "cz" && this.currencyTarget.value !== "CZK" && this.buyerCountryTarget.value !== "CZ") ||
        (this.accountCountryValue === "sk" && this.currencyTarget.value === "EUR" && this.buyerCountryTarget.value !== "SK")) {
        this.showIbanTarget.checked = true
      } else {
        this.showIbanTarget.checked = false
      }
    }
  }

  updateCondNote() {
    if (this.defaultCondNote && this.buyerCountryTarget.value !== "") {
      const buyerCountry = this.buyerCountryTarget.value === "MC" ? "FR" : this.buyerCountryTarget.value
      const sellerCountry = this.sellerCountryTarget.value === "MC" ? "FR" : this.sellerCountryTarget.value

      if (sellerCountry === buyerCountry) {
        this.condNoteTarget.value = this.defaultCondNotesValue.national_cond_note
        return
      }

      if (this.hasMossOutlet && this.mossOutlet.useMossTarget.checked) {
        this.condNoteTarget.value = this.defaultCondNotesValue.oss_cond_note
        return
      }
      let noTax = true

      if (this.kindValue === "correction") {
        // TODO fv korekty
        // while ($('#invoices_positions_attributes_' + i + '_correction_after_attributes_tax').length > 0) {
        //  var curr_tax = $('#invoice_positions_attributes_' + i + '_correction_after_attributes_tax').val();
        //  if (curr_tax != 0 && curr_tax != 'disabled') {
        //   no_tax = false;
        //   break;
        //  }
        //  i++;
        // }
      } else {
        for (const elem of document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_tax']")) {
          if (elem.value !== 0 && elem.value !== "disabled") {
            noTax = false
            break
          }
        }
      }

      if (noTax && this.euCountriesValue.includes(sellerCountry)) {
        if (this.euCountriesValue.includes(buyerCountry)) {
          this.condNoteTarget.value = this.defaultCondNotesValue.EU_cond_note
        } else {
          this.condNoteTarget.value = this.defaultCondNotesValue.outside_EU_cond_note
        }
        return
      }
      this.condNoteTarget.value = ""
    }
  }

  updateDefaultCondNote() {
    this.defaultCondNote = false
  }

  get autocompleteTax() {
    return !(this.hasDesOutlet && this.desOutlet.useDesTarget.checked)
  }

  fillWithDefaultTax(position = null) {
    if (this.taxVisibleValue && !["wdt", "export_products"].includes(this.kindValue) && this.autocompleteTax) {
      this.setDefaultTax()
      if (this.defaultTax === undefined || this.defaultTax === null || this.defaultTax === "") { return }
      if (position) {
        forceSetValueOnSelect(document.getElementById(`invoice_positions_attributes_${position}_tax`), this.defaultTax)
        document.getElementById(`invoice_positions_attributes_${position}_tax`).dispatchEvent(new Event("change"))
      } else {
        if (confirm(this.translationsValue["default_tax_all_confirm"])) {
          document.querySelectorAll("[id^=invoice_positions_attributes_][id$='_tax']").forEach((elem) => {
            forceSetValueOnSelect(elem, this.defaultTax)
            elem.dispatchEvent(new Event("change"))
          })
        }
      }
    }
  }

  /**
   * ustawia domyślny podatek z działu/firmy
   */
  setDefaultTax() {
    if (this.hasDepartmentSelectTarget) {
      const deptId = this.departmentSelectTarget.value
      const deptAttributes = this.departmentsValue[deptId] ?? {}
      if (!isEmpty(deptAttributes["default_tax"]) && deptAttributes["force_tax_rate"] === "1") {
        this.defaultTax = deptAttributes["default_tax"]
      }
    }
  }

  toggleUsePrimeCee() {
    const checkbox = this.usePrimeCeeTarget
    checkbox.checked = !checkbox.checked
    if (checkbox.checked) {
      this.primeCeeWrapperTarget.classList.remove("hidden")
    } else {
      this.primeCeeWrapperTarget.classList.add("hidden")
    }
  }

  /** @param {HTMLOptionElement[]} options  */
  setTaxOptions(options) {
    let allTaxesMatched = true
    this.invoicePositionOutlets.forEach((position) => {
      allTaxesMatched &&= position.setTaxOptions(options)
    })
    return allTaxesMatched
  }

  /**
   * przywraca możliwe stawki podatku z momentu załadowania strony
   */
  setDefaultTaxes() {
    this.setTaxOptions(this.defaultTaxOpts)
  }

  closeDocumentInfo({ params: { noticeStatusUrl } }) {
    Rails.ajax({
      type: "post",
      url: noticeStatusUrl,
      success: () => this.documentInfoWrapperTarget.classList.add("hidden"),
    })
  }

  /**
   * @param {Event} event
   */
  confirmCreateFromEstimate(event) {
    const { params: { linkedAdvancesTranslation, lastAdvancePath } } = event
    const escaper = document.createElement("div")
    escaper.innerHTML = linkedAdvancesTranslation
    if (!confirm(escaper.innerText)) {
      event.preventDefault()
      Turbo.visit(lastAdvancePath)
    }
  }

  tryChangePriceOfferSummary() {
    if (this.priceOfferNoCatalogueTarget.checked) {
      this.priceOfferSummaryTarget.checked = true
    }
  }

  changePriceOfferNoCatalogue(event) {
    const { params: { normalClass, disabledClass } } = event
    this.priceOfferSummaryTarget.checked = this.priceOfferNoCatalogueTarget.checked
    if (this.priceOfferNoCatalogueTarget.checked) {
      this.priceOfferSummaryTarget.className = disabledClass
    } else {
      this.priceOfferSummaryTarget.className = normalClass
    }
  }

  showPaymentModal() {
    this.paymentModalTarget.classList.remove("hidden")
  }

  hidePaymentModal() {
    this.paymentModalTarget.classList.add("hidden")
  }

  showTaxNoExistNotification() {
    showNotification(this.translationsValue["moss_tax_no_exist"])
  }

  showTaxNoNotExistNotification() {
    showNotification(this.translationsValue["des_tax_no_not_exist"])
  }

  tickShouldUpdateDepartment() {
    this.element.querySelector("#invoice_should_update_department").checked = true
  }

  zeroTaxAlert() {
    const hasSomeZeroTax = this.taxFieldTargets.some(elem => elem.value === "0")
    const buyerCountry = this.buyerCountryTarget.value
    if (hasSomeZeroTax && buyerCountry !== "" && buyerCountry !== "PL") {
      if (this.element.querySelector('#invoice_buyer_company_true')?.checked && this.euCountriesValue.includes(buyerCountry) && this.kindValue !== "wdt") {
        alert(this.translationsValue["invoice.could_be_wdt"])
      } else if (this.element.querySelector('#invoice_buyer_company_true')?.checked && !this.euCountriesValue.includes(buyerCountry) && this.kindValue !== "export_products") {
        alert(this.translationsValue["invoice.could_be_export_products"])
      } else if (!(this.hasMossOutlet && this.mossOutlet.useMossTarget.checked)) {
        alert(this.translationsValue["invoice.could_be_vat_oss"])
      }
    }
  }

  updateViesVisibility() {
    if (this.hasViesButtonTarget) {
      if (this.euCountriesValue.includes(this.buyerCountryTarget.value) && this.euCountriesValue.includes(this.sellerCountryTarget.value) &&
        ["vat", "proforma", "wdt", "export_products"].includes(this.kindValue) && this.buyerCountryTarget.value !== this.sellerCountryTarget.value) {
        this.viesButtonTarget.classList.remove("hidden")
      } else {
        this.viesButtonTarget.classList.add("hidden")
      }
    }
  }

  updateDeliveryAddressVisibility(event) {
    if (event.currentTarget.checked) {
      this.deliveryAddressWrapperTarget.classList.remove("hidden")
    } else {
      this.deliveryAddressWrapperTarget.classList.add("hidden")
    }
  }

  updatePriceList(event) {
    if (this.defaultPriceList === event.target.value || !confirm(this.translationsValue["price_list_change_confirm"])) {
      event.target.value = this.defaultPriceList
      return
    }

    this.defaultPriceList = event.target.value

    this.recalculatePositionsForNewPriceList()

    this.currencyTarget.value = event.target.selectedOptions[0].getAttribute("data-currency") || this.defaultCurrencyValue
    this.currencyTarget.dispatchEvent(new Event("change"))
  }

  recalculatePositionsForNewPriceList() {
    const params = new URLSearchParams()
    this.invoicePositionOutlets.forEach(position => {
      if (position.hasProductIdTarget && position.productIdTarget.value.length > 0) {
        params.append("product_ids[]", position.productIdTarget.value)
      }
    })

    if (params.size === 0) {
      return
    }

    const priceList = this.defaultPriceList || 0

    const attrs = ["tax", "price_net", "price_gross", "currency"]
    Rails.ajax({
      url: "/price_lists/" + priceList + "/prices_for_products.json",
      type: "get",
      data: params,
      success: (data) => {
        for (const position of this.invoicePositionOutlets) {
          if (!position.hasProductIdTarget || position.productIdTarget.value.length === 0) continue

          attrs.forEach((attr) => {
            if (attr === "tax") {
              forceSetValueOnSelect(document.getElementById(`invoice_positions_attributes_${position.positionIdValue}_tax`), data[position.productIdTarget.value][attr])
            } else {
              document.getElementById(`invoice_positions_attributes_${position.positionIdValue}_${attr}`).value = data[position.productIdTarget.value][attr]
            }
          })
          document.getElementById(`invoice_positions_attributes_${position.positionIdValue}_tax`).dispatchEvent(new Event("change"))
        }
      }
    })
  }

  checkRoundPrice(event) {
    if (event.params.roundPriceDefault === "cash") {
      this.roundPriceTarget.checked = (event.target.value === "cash")
    } else if (event.params.roundPriceDefault === "always") {
      this.roundPriceTarget.checked = true
    }
  }

  setBankAccountNumberFromInvoice() {
    if (this.fromInvoiceSellerBankAccountValue &&
      this.departmentsValue[this.departmentSelectTarget.value]["bank_account"] &&
      this.fromInvoiceSellerBankAccountValue !== this.departmentsValue[this.departmentSelectTarget.value]["bank_account"] &&
      document.getElementById("allowed_to_change_bank_account").value === "true") {
      if (confirm(this.translationsValue["bank_account_has_changed"] + " " + this.translationsValue["change_bank_account_to_source_invoice"])) {
        this.sellerBankAccountTarget.value = this.fromInvoiceSellerBankAccountValue
        this.sellerBankTarget.value = this.fromInvoiceSellerBankValue
        this.sellerBankSwiftTarget.value = this.fromInvoiceSellerBankSwiftValue
        this.sellerBankAccountInfoTarget.innerText = this.sellerBankTarget.value + " " + this.sellerBankAccountTarget.value
        document.getElementById("keep_bank_account_number_from_invoice").value = true
      }
    } else if (this.fromInvoiceSellerBankAccountValue &&
      this.departmentsValue[this.departmentSelectTarget.value]["bank_account"] &&
      this.fromInvoiceSellerBankAccountValue !== this.departmentsValue[this.departmentSelectTarget.value]["bank_account"] &&
      document.getElementById("allowed_to_change_bank_account").value === "false") {
      growl(this.translationsValue["bank_account_has_changed"] + " " + this.translationsValue["changed_bank_account_to_default"])
    }
  }
}
