import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hideButton", "loaderButton"]

  hideAndShow() {
    // hide target button
    this.hideButtonTarget.classList.add("hidden")
    // show loader button
    this.loaderButtonTarget.classList.remove("hidden")
  }
}
