import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ["menu", "icon", "altIcon", "title"]
  static values = { open: String, close: String }

  connect() {
    useTransition(this, { element: this.menuTarget })
  }

  toggle() {
    this.toggleTransition()

    if (this.hasAltIconTarget) {
      this.iconTarget.classList.toggle('hidden')
      this.altIconTarget.classList.toggle('hidden')
    } else if (this.hasIconTarget) {
      this.iconTarget.classList.toggle("-rotate-180")
    }

    if (this.hasTitleTarget) {
      this.titleTarget.innerHTML = this.iconTarget.classList.contains("-rotate-180") ? this.closeValue : this.openValue
    }
  }
}
