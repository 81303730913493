import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "toggleButton"]
  static values = {
    class: String,
    lines: { type: Number, default: 2 }
  }

  connect() {
    const lineHeight = parseInt(getComputedStyle(this.contentTarget).lineHeight)
    const targetHeight = this.linesValue * lineHeight
    if (this.contentTarget.offsetHeight > targetHeight) {
      this.contentTarget.classList.toggle(this.classValue)
      this.toggleButtonTarget.classList.remove("hidden")
    }
  }

  toggle() {
    this.contentTarget.classList.toggle(this.classValue)
    this.toggleButtonTarget.classList.add("hidden")
  }
}
