import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["shortcutName"]

  connect() {
    console.log("department connect")
  }

  setShortcut(event) {
    if (this.hasShortcutNameTarget && this.shortcutNameTarget.value === "") {
      this.shortcutNameTarget.value = event.currentTarget.value
    }
  }
}
