import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["additionalGroup", "open", "close"]

  connect() {
    console.log("additional-options connect")
  }

  toggle() {
    [this.additionalGroupTarget, this.closeTarget, this.openTarget].forEach((element) => element.classList.toggle("hidden"))
  }
}
