import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    dismissAfter: Number,
    showDelay: { type: Number, default: 200 },
    removeDelay: { type: Number, default: 1100 }
  }

  static targets = ["messages"]

  dismissTimeoutHandle = null

  initialize() {
    this.hide()
  }

  connect() {
    if (this.hasMessagesTarget && this.messagesTarget.innerHTML.trim()) {
      this.init()
    }
  }

  init() {
    setTimeout(() => {
      this.show()
    }, this.showDelayValue)

    // Auto dimiss if defined
    if (this.hasDismissAfterValue) {
      if (this.dismissTimeoutHandle !== null) {
        clearTimeout(this.dismissTimeoutHandle)
      }
      this.dismissTimeoutHandle = setTimeout(() => {
        this.dismissTimeoutHandle = null
        this.close()
      }, this.dismissAfterValue)
    }
  }

  close() {
    this.hide()

    if (this.hasMessagesTarget) {
      setTimeout(() => {
        this.clearMessages()
      }, this.removeDelayValue)
    }
  }

  show() {
    this.element.classList.remove('hidden')
  }

  hide() {
    this.element.classList.add('hidden')
  }

  addMessage(event) {
    if (event.detail.message.length > 0) {
      this.messagesTarget.insertAdjacentHTML('beforeend', `<p>${event.detail.message}</p>`)
      this.init()
    }
  }

  clearMessages() {
    if (this.hasMessagesTarget) {
      this.messagesTarget.innerHTML = ""
    }
  }
}
