/**
 * odpowiednik Firmlet.changed
 * @param {HTMLInputElement} element
 */
export function valueChangedFromDefault(element) {
  return element.defaultValue !== element.value
}

/**
 * odpowiednik isEmptyObject z jQuery
 * to niestety działa i dla obiektów i dla stringów i tak było używane
 * @param {any} object
 * @returns {boolean}
 */
export function isEmpty(object) {
  for (const x in object) {
    return false
  }
  return true
}

/**
 * odpowiednik Firmlet.force_set_val_on_select
 * ustawia wartość danego selecta na value
 * tworzy brakujące option jeśli go nie ma
 * @param {HTMLSelectElement} select
 * @param {string} value
 * @returns {void}
 */
export function forceSetValueOnSelect(select, value) {
  if (!select.matches("select")) {
    // pewnie to input a nie select
    select.value = value
    return
  }

  for (const option of select.options) {
    if (option.value === value) {
      select.value = value
      return
    }
  }
  const newOption = document.createElement("option")
  newOption.value = newOption.innerText = value
  select.append(newOption)
  select.value = value
}

/**
 * odpowiednik Firmlet.escape_html
 * @param {string} s
 * @returns {string} s jako poprawny bezpieczny HTML
 */
export function escapeHtml(s) {
  const elem = document.createElement("div")
  elem.innerText = s
  return elem.innerHTML
}

/**
 * @param {Date} date
 * @returns {string}
 */
export function isoDateToString(date) {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return `${year}-${month}-${day}`
}

export function formatPriceSetup(locale) {
  let formattedExample = Intl.NumberFormat(locale).format("11111.1")
  formattedExample = formattedExample.trim()
  const thousandSeparator = formattedExample.length > 7 ? formattedExample.charAt(2) : ""
  const decimalSeparator = formattedExample.charAt(formattedExample.length - 2)

  return { thousandSeparator, decimalSeparator }
}

export function formatNumber(value, options) {
  const opts = formatPriceSetup(options.locale)
  let formattedValue = new Intl.NumberFormat(options.locale, {
    style: "decimal",
    minimumFractionDigits: options.app_digits,
    maximumFractionDigits: options.app_digits,
    useGrouping: "always"
  }).format(value).replace(/\s+/g, " ")
  formattedValue = formattedValue.replaceAll(opts.thousandSeparator, " ")
  return formattedValue
}

export function parseNumber(value, options) {
  if (value.length > 0) {
    value = value.replace(/[\s\u00A0]+/g, "")
    const opts = formatPriceSetup(options.locale)
    if (opts.thousandSeparator === "," && value.indexOf(".") === -1) {
      value = value.replaceAll(opts.thousandSeparator, ".")
    }
    const normalized = value.replace(opts.decimalSeparator, ".")
    return parseFloat(normalized)
  } else {
    return Number(0)
  }
}

/**
 * tworzy powiadomienie we frontendzie tailwind_mobile
 * odpowiednik growl w bootstrapie
 * @param {string} message
 * @param {boolean} withReplace
 * @param {Array} replaceFrom
 * @param {Array} replaceTo
 */
export function showNotification(message, withReplace = false, replaceFrom = [], replaceTo = []) {
  // let msg = this.translationsValue[message]
  if (withReplace) {
    replaceFrom.forEach((el, index) => {
      if (index in replaceTo) {
        message = message.replace(el, replaceTo[index])
      }
    })
  }
  window.dispatchEvent(new CustomEvent("update-message", { detail: { message } }))
}
