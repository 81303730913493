import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["clientAdditionalGroup", "clientAdditionalOpen", "clientAdditionalClose", "tax", "clientTaxNo", "clientName",
    "deliveryAddressWrapper"
  ]

  static values = {
    locale: String,
    newRecord: Boolean,
  }

  connect() {
    console.log("warehouse connect")
  }

  switchClientType(event) {
    if (event.target.value === "true") {
      document.querySelectorAll("[data-client-person]").forEach((element) => element.classList.add("hidden"))
      document.querySelectorAll("[data-client-company]").forEach((element) => element.classList.remove("hidden"))
      document.querySelector("#warehouse_document_client_name").closest("[data-client-company]").after(this.taxTarget)
      if (this.localeValue === "fr") {
        this.taxTarget.classList.remove("hidden")
      }
    } else {
      document.querySelectorAll("[data-client-company]").forEach((element) => element.classList.add("hidden"))
      document.querySelectorAll("[data-client-person]").forEach((element) => element.classList.remove("hidden"))

      if (this.localeValue === "fr") {
        this.taxTarget.classList.add("hidden")
      } else if (this.clientTaxNoTarget.value.length === 0) {
        this.clientAdditionalGroupTarget.append(this.taxTarget)
      }
    }
  }

  companySetName() {
    this.clientNameTarget.value = document.getElementById("warehouse_document_client_first_name").value + " " + document.getElementById("warehouse_document_client_last_name").value
  }

  updateDeliveryAddressVisibility(event) {
    if (event.currentTarget.checked) {
      this.deliveryAddressWrapperTarget.classList.remove("hidden")
    } else {
      this.deliveryAddressWrapperTarget.classList.add("hidden")
    }
  }
}
